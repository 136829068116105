import React from 'react';
import CheckpointCompletionTargetInsight from "./CheckpointCompletionTarget";
import MaxParallelWorkersInsight from "./MaxParallelWorkers";
import StatementTimeoutInsight from "./StatementTimeout";
import TrackIOTimingInsight from "./TrackIOTiming";
import MaintenanceWorkMemInsight from "./MaintenanceWorkMem";
import MaxWalSizeInsight from "./MaxWalSize";
import MinWalSizeInsight from "./MinWalSize";
import SharedBuffersInsight from "./SharedBuffers";
import MaxWorkerProcessesInsight from "./MaxWorkerProcesses";
import MaxParallelWorkersPerGatherInsight from "./MaxParallelWorkersPerGather";
import IdleInTransactionSessionTimeoutInsight from "./IdleInTransactionSessionTimeout";
import PGTrgmInsight from "./PGTrgm";
import EffectiveIOConcurrencyInsight from "./EffectiveIOConcurrency";
import PGStatStatementExistsInsight from "./PGStatStatementExists";
import AutoExplainLogAnalyzeInsight from "./AutoExplain.LogAnalyze";
import AutoExplainLogMinDurationInsight from "./AutoExplain.LogMinDuration";
import InnodbBufferPoolSizeInsight from "./InnodbBufferPoolSize";
import InnodbBufferPoolInstancesInsight from "./InnodbBufferPoolInstances";
import InnodbDoubleWriteInsight from "./InnodbDoubleWrite";
import InnodbFlushLogAtTrxCommitInsight from "./InnodbFlushLogAtTrxCommit";
import WorkMem from "./WorkMem";
import LogMinDurationStatementInsight from "./LogMinDurationStatement";

const ConfigurationInsightTypes = {
    STATEMENT_TIMEOUT: 'STATEMENT_TIMEOUT',
    MAX_PARALLEL_WORKERS: 'MAX_PARALLEL_WORKERS',
    CHECKPOINT_COMPLETION_TARGET: 'CHECKPOINT_COMPLETION_TARGET',
    IDLE_IN_TRANSACTION_SESSION_TIMEOUT: 'IDLE_IN_TRANSACTION_SESSION_TIMEOUT',
    EFFECTIVE_IO_CONCURRENCY: 'EFFECTIVE_IO_CONCURRENCY',
    MAX_WORKER_PROCESSES: 'MAX_WORKER_PROCESSES',
    EXTENSIONS_PG_TRGM_EXISTS: 'EXTENSIONS_PG_TRGM_EXISTS',
    TRACK_IO_TIMING: 'TRACK_IO_TIMING',
    LOG_MIN_DURATION_STATEMENT: 'LOG_MIN_DURATION_STATEMENT',
    INNO_DB_FLUSH_LOG_AT_TRX_COMMIT: 'INNO_DB_FLUSH_LOG_AT_TRX_COMMIT',
    INNO_DB_DOUBLE_WRITE: 'INNO_DB_DOUBLE_WRITE',
    INNO_DB_BUFFER_POOL_INSTANCE: 'INNO_DB_BUFFER_POOL_INSTANCE',
    INNO_DB_FLUSH_LOG: 'INNO_DB_FLUSH_LOG',
    EXTENSIONS_PG_STAT_STATEMENTS_EXISTS: 'EXTENSIONS_PG_STAT_STATEMENTS_EXISTS',
    EXPLAIN_LOG_ANALYZE: 'EXPLAIN_LOG_ANALYZE',
    WORK_MEM: 'WORK_MEM',
    INNO_DB_BUFFER_POOL_SIZE: 'INNO_DB_BUFFER_POOL_SIZE',
    MIN_WAL_SIZE: 'MIN_WAL_SIZE',
    MAINTENANCE_WORK_MEM: 'MAINTENANCE_WORK_MEM',
    PG_AUTO_EXPLAIN_LOG_ANALYZE: 'PG_AUTO_EXPLAIN_LOG_ANALYZE',
    MAX_WAL_SIZE: 'MAX_WAL_SIZE',
    EXPLAIN_LOG_MIN_DURATION: 'EXPLAIN_LOG_MIN_DURATION',
    SHARED_BUFFERS: 'SHARED_BUFFERS',
    MAX_PARALLEL_WORKERS_PER_GATHER: 'MAX_PARALLEL_WORKERS_PER_GATHER',
} as const;

export type ConfigurationInsightTypes = typeof ConfigurationInsightTypes[keyof typeof ConfigurationInsightTypes];

export type ConfigurationInsightsProps = {
    type: ConfigurationInsightTypes | string;
    severity: number;
    params: object;
}

type ConfigurationInsightsMapperProps = {
    insights: ConfigurationInsightsProps[];
};

export const ConfigurationPropertyToInsightMap: Record<string, ConfigurationInsightTypes[]> = {
    'auto_explain.log_analyze': [ConfigurationInsightTypes.EXPLAIN_LOG_ANALYZE],
    'auto_explain.log_min_duration': [ConfigurationInsightTypes.EXPLAIN_LOG_MIN_DURATION],
    'checkpoint_completion_target': [ConfigurationInsightTypes.CHECKPOINT_COMPLETION_TARGET],
    'effective_io_concurrency': [ConfigurationInsightTypes.EFFECTIVE_IO_CONCURRENCY],
    'idle_in_transaction_session_timeout': [ConfigurationInsightTypes.IDLE_IN_TRANSACTION_SESSION_TIMEOUT],
    'maintenance_work_mem': [ConfigurationInsightTypes.MAINTENANCE_WORK_MEM],
    'max_parallel_workers': [ConfigurationInsightTypes.MAX_PARALLEL_WORKERS],
    'max_parallel_workers_per_gather': [ConfigurationInsightTypes.MAX_PARALLEL_WORKERS_PER_GATHER],
    'max_worker_processes': [ConfigurationInsightTypes.MAX_WORKER_PROCESSES],
    'max_wal_size': [ConfigurationInsightTypes.MAX_WAL_SIZE],
    'min_wal_size': [ConfigurationInsightTypes.MIN_WAL_SIZE],
    'shared_buffers': [ConfigurationInsightTypes.SHARED_BUFFERS],
    'statement_timeout': [ConfigurationInsightTypes.STATEMENT_TIMEOUT],
    'track_io_timing': [ConfigurationInsightTypes.TRACK_IO_TIMING],
    'pg_trgm': [ConfigurationInsightTypes.EXTENSIONS_PG_TRGM_EXISTS],
    'pg_stat_statements': [ConfigurationInsightTypes.EXTENSIONS_PG_STAT_STATEMENTS_EXISTS],
    'innodb_buffer_pool_size': [ConfigurationInsightTypes.INNO_DB_BUFFER_POOL_SIZE],
    'innodb_buffer_pool_instances': [ConfigurationInsightTypes.INNO_DB_BUFFER_POOL_INSTANCE],
    'innodb_doublewrite': [ConfigurationInsightTypes.INNO_DB_DOUBLE_WRITE],
    'innodb_flush_log_at_trx_commit': [ConfigurationInsightTypes.INNO_DB_FLUSH_LOG_AT_TRX_COMMIT],
    'work_mem': [ConfigurationInsightTypes.WORK_MEM],
    'log_min_duration_statement': [ConfigurationInsightTypes.LOG_MIN_DURATION_STATEMENT],
};

const ConfigurationInsightsMapper: React.FC<ConfigurationInsightsMapperProps> = ({insights}) => {

    return insights.map(insight => {
        const { type, severity, params } = insight;
        switch (type) {
            case ConfigurationInsightTypes.STATEMENT_TIMEOUT:
                return <StatementTimeoutInsight severity={severity}/>
            case ConfigurationInsightTypes.MAX_PARALLEL_WORKERS:
                return <MaxParallelWorkersInsight severity={severity}/>
            case ConfigurationInsightTypes.LOG_MIN_DURATION_STATEMENT:
                return <LogMinDurationStatementInsight severity={severity} params={params}/>
            case ConfigurationInsightTypes.TRACK_IO_TIMING:
                return <TrackIOTimingInsight severity={severity}/>
            case ConfigurationInsightTypes.MAINTENANCE_WORK_MEM:
                return <MaintenanceWorkMemInsight severity={severity}/>
            case ConfigurationInsightTypes.MAX_WAL_SIZE:
                return <MaxWalSizeInsight severity={severity}/>
            case ConfigurationInsightTypes.MIN_WAL_SIZE:
                return <MinWalSizeInsight severity={severity}/>
            case ConfigurationInsightTypes.CHECKPOINT_COMPLETION_TARGET:
                return <CheckpointCompletionTargetInsight severity={severity}/>
            case ConfigurationInsightTypes.SHARED_BUFFERS:
                return <SharedBuffersInsight severity={severity}/>
            case ConfigurationInsightTypes.MAX_WORKER_PROCESSES:
                return <MaxWorkerProcessesInsight severity={severity}/>
            case ConfigurationInsightTypes.MAX_PARALLEL_WORKERS_PER_GATHER:
                return <MaxParallelWorkersPerGatherInsight severity={severity}/>
            case ConfigurationInsightTypes.IDLE_IN_TRANSACTION_SESSION_TIMEOUT:
                return <IdleInTransactionSessionTimeoutInsight severity={severity}/>
            case ConfigurationInsightTypes.EXTENSIONS_PG_TRGM_EXISTS:
                return <PGTrgmInsight severity={severity}/>
            case ConfigurationInsightTypes.EFFECTIVE_IO_CONCURRENCY:
                return <EffectiveIOConcurrencyInsight severity={severity}/>
            case ConfigurationInsightTypes.EXTENSIONS_PG_STAT_STATEMENTS_EXISTS:
                return <PGStatStatementExistsInsight severity={severity}/>
            case ConfigurationInsightTypes.EXPLAIN_LOG_ANALYZE:
                return <AutoExplainLogAnalyzeInsight severity={severity} params={params}/>
            case ConfigurationInsightTypes.EXPLAIN_LOG_MIN_DURATION:
                return <AutoExplainLogMinDurationInsight severity={severity} params={params}/>
            case ConfigurationInsightTypes.INNO_DB_BUFFER_POOL_SIZE:
                return <InnodbBufferPoolSizeInsight severity={severity} params={params}/>
            case ConfigurationInsightTypes.INNO_DB_BUFFER_POOL_INSTANCE:
                return <InnodbBufferPoolInstancesInsight severity={severity} params={params}/>
            case ConfigurationInsightTypes.INNO_DB_DOUBLE_WRITE:
                return <InnodbDoubleWriteInsight severity={severity}/>
            case ConfigurationInsightTypes.INNO_DB_FLUSH_LOG:
                return <InnodbFlushLogAtTrxCommitInsight severity={severity} params={params}/>
            case ConfigurationInsightTypes.WORK_MEM:
                return <WorkMem severity={5}/>
            default:
                return null;
        }

    })
};

export default ConfigurationInsightsMapper;