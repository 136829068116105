import styled from 'styled-components';
import {boolean} from "zod";


export const NodeContainer = styled.div<{ nodeType: string }>`
  padding: 5px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  width: 250px;
  position: relative;
  text-align: center;
`;

export const Label = styled.div<{ nodeType: string }>`
    color: black;
    background-color: ${({ nodeType }) =>
            nodeType === 'table' ? '#87cefa' : nodeType === 'result' ? '#b595f5' : 'lightgray'};
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DetailText = styled.p`
  margin: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const AccessMethodValue = styled.span<{ isTableScan?: boolean }>`
  color: ${({ isTableScan }) => (isTableScan ? 'red' : 'inherit')};
  margin-left: 5px;
`;

export const IndexTag = styled.span`
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 12px;
  display: inline-block;
  background-color :  #98FB98;
  color: #00853E;
`;
