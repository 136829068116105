import { useRecoilState, useRecoilValue } from 'recoil';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Skeleton, Tab, Tabs } from '@mui/material';
import { ReactComponent as InfoI } from '@icons/info-primary.svg';
import { useTheme } from 'styled-components';

import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import { extractApiKeyByHost, getFormattedDate, getNaChartValue, numberWithCommas, toNumbersSuffix } from '../../../utils/utils';
import Flex from '../../../core/Flex';
import {
  BodyDrawer,
  Close,
  Container,
  DataGridUI,
  DataGridUIContainer,
  TableHeader,
  MainInnerDrawer,
  MainTitle,
  TabContainer,
  TitleDrawer
} from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { DataTable, ReportAtomState } from './types';
import MDrawer from '../../../core/Drawer/Drawer';
import { TableTabState } from '../utils';
import {
  getSSA_API,
  getTableDBIdAndParamsAPI,
  getTableIndexesAPI,
  getTableInsightsAPI
} from '../../SchemaAnalysis/SchemaAnalysisAPI';
import { analyticsTrack } from '../../../utils/segment-analytics';
import { getTableReadWriteAnalysisAPI, getTableRowSizeAPI } from '../utils/ReportAPI';
import MTooltip from '../../../core/Tooltip/Tooltip';
import Typography from '../../../core/Typography';
import { TabPanel } from '../../../core/Tabs/Tabs';
import TableItem from '../../../components/Table/TableItem';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import Button from '../../../core/Button/Button';
import { debugModeState } from '../../../atoms/debug-mode.atom';
import Select from '../../../components/Select/Select';
import { RDBMS, SelectListItem, listSelectedDates } from '../../../utils/constants';
import { HChart } from '../../ObservabilityDashboard/components';
import { BYTE_SIZE, byteMe } from '../../../utils/byteMe';
import { userSettingsAtom } from '../../../atoms/user.settings.atom';
import {getSeverityCircle} from "../../SchemaAnalysis/ListTree";
import {ValueFormatterParams} from "ag-grid-community";
import TableInsightsMapper, {TableInsightsProps} from "../../../components/InsightsAccordion/TableInsights/TableInsightsMapper";

const ExtraInfoDetails = () => {
  return (
    <div>
      -1 indicates that the table has never yet been vacuumed or analyzed, thus row (<code>pg_catalog.reltuples</code>) count is
      unknown. Run the <code>ANALYZE</code> command to generate statistics. For ex. <code>ANALYZE</code> <b>my_table</b>
    </div>
  );
};

const CustomizeRowReadHeader = (props: any, rdbms: RDBMS | '') => {
  const [sortState, setSortState] = useState('asc');
  if (rdbms === RDBMS.mysql) {
    return (
      <Typography weight="600" style={{ paddingRight: '8px' }}>
        Number of rows
      </Typography>
    );
  }
  return (
    <MTooltip title={<ExtraInfoDetails />}>
      <Flex
        onClick={(e: any) => {
          props?.setSort && props.setSort(sortState, e.shiftKey);
          setSortState(sortState === 'desc' ? 'asc' : 'desc');
        }}
      >
        <Typography weight="600" style={{ paddingRight: '8px' }}>
          Number of rows
        </Typography>
        <InfoI height={14} width={14} />
      </Flex>
    </MTooltip>
  );
};

export const TableRowSize = () => {
  const type: ReportAtomState = 'dataTable';
  const restClient = useContext(RestClientContext);
  const [debugDrawerState, setDebugDrawerState] = useState(false);
  const isDebugMode = useRecoilValue(debugModeState);
  const { colors }: any = useTheme();
  const { state }: any = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [tabState, setTabState] = useState<number>(0);
  const [isSSALoading, setSSALoading] = useState(true);
  const [selectedTableItem, setSelectedItem] = useState<any>(null);
  const [{ [type]: reportsState, selectedType, rdbms }, setReportsState] = useRecoilState(reportsStateAtom);
  const [selectedDate, setSelectedDay] = useState<SelectListItem>(listSelectedDates[0]);
  const [tableReadWriteAnalysis, setTableReadWriteAnalysis] = useState([]);
  const { hostId = '', databaseId = '' } = useParams();
  const [tableAnalysisItem, setTableReadWriteAnalysisItem] = useState<any>();
  const [tableIndexes, setTableIndexes] = useState<any>();
  const [tableInsights, setTableInsights] = useState<Record<number, TableInsightsProps[]>>({});
  const [tablesInfo, setTablesInfo] = useState<any>([]);
  const settings = useRecoilValue(userSettingsAtom);
  const handleTabChange = (event: any, newValue: number) => {
    setTabState(newValue);
  };

  const getTableInsights = async (selectedTableItem: any) => {
    const tableId = selectedTableItem?.context?.table_id;
    const schemaId = selectedTableItem?.context?.schema_name;
    const res: TableInsightsProps[] = await getTableInsightsAPI(restClient, hostId, databaseId, schemaId, tableId, extractApiKeyByHost(hostId, settings.hostsApiKey))
    setTableInsights({
        ...tableInsights,
        [tableId]: res
    });
  }
  useEffect(() => {
    if (selectedTableItem) {
      getTableInsights(selectedTableItem);
    }
  }, [selectedTableItem]);

  useEffect(() => {
    if (tablesInfo.length > 0) {
      const newColumnDefs = [...columnDefs];
      const tableNameDef = {
        field: 'table_name',
        cellStyle: {justifyContent: 'start', alignItems: 'center', display: 'flex'},
        headerName: 'Table Name',
        sortable: true,
        cellRenderer: insightsFormatter,
        valueGetter: (params: any) => `${params?.data?.schema_name?.[0]?.value}.${params?.data?.context?.table_name}`,
      };

      newColumnDefs[0] = tableNameDef;
      setColumnDefs(newColumnDefs)
    }
  }, [tablesInfo]);

  const insightsFormatter = (params: ValueFormatterParams) => {

    const { value } = params;
    const [schema_name, table_name] = value.split('.');

    const insightsData = tablesInfo.find((table) => table.schema_name === schema_name
        && table.table_name === table_name)
    return <><span>{schema_name}.{table_name}</span>
      <span style={{marginLeft: 7}}>
      {insightsData?.severity_critical > 0 ? getSeverityCircle('#F20530'): <></>}
      {insightsData?.severity_high > 0 ? getSeverityCircle('#FF790B'): <></>}
      {insightsData?.severity_medium > 0 ? getSeverityCircle('#FFCC00'): <></>}
      {insightsData?.severity_low > 0 ? getSeverityCircle('#03D0FF'): <></>}</span></>
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'table_name',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Table Name',
      sortable: true,
      cellRenderer: insightsFormatter,
      valueGetter: (params: any) => `${params?.data?.schema_name?.[0]?.value}.${params?.data?.context?.table_name}`,
    },
    {
      headerName: 'Number of rows',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerComponent: (params: any) => CustomizeRowReadHeader(params, rdbms),
      sortable: true,
      valueGetter: (params: any) => params?.data?.num_of_rows?.[params?.data?.num_of_rows?.length - 1]?.value,
      valueFormatter: (params: any) => numberWithCommas(params?.data?.num_of_rows?.[params?.data?.num_of_rows?.length - 1]?.value)
    },
    {
      headerName: 'Dead Rows',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params?.data?.dead_rows?.[params?.data?.dead_rows?.length - 1]?.value,
      valueFormatter: (params: any) => numberWithCommas(params?.data?.dead_rows?.[params?.data?.dead_rows?.length - 1]?.value)
    }
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );

  const getReports = async () => {
    setLoading(true);
    const tableRowSizeRes: { rows: DataTable[]; lastUpdateIsoDate: string; rdbms?: RDBMS | '' } | undefined =
      await getTableRowSizeAPI(restClient, extractApiKeyByHost(hostId, settings.hostsApiKey), databaseId);
    if (tableRowSizeRes?.rows?.length) {
      setSelectedItem(tableRowSizeRes.rows[0]);
    }

    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: tableRowSizeRes?.rows || [], lastUpdateIsoDate: tableRowSizeRes?.lastUpdateIsoDate || '' },
      rdbms: tableRowSizeRes?.rdbms || state.rdbms || ''
    }));

    const { listItem } = await getSSA_API(restClient, databaseId, extractApiKeyByHost(hostId, settings.hostsApiKey));

    setTablesInfo(listItem);

    setLoading(false);

  };
  const onSelectDate = (val: any): void => {
    const selected = listSelectedDates.find((i) => i.val === val) || listSelectedDates[0];

    setSelectedDay(selected);
  };

  const getTableReadWriteAnalysis = async () => {
    const resTableAnalysis: any = await getTableReadWriteAnalysisAPI(
      restClient,
      extractApiKeyByHost(hostId, settings.hostsApiKey),
      databaseId,
      selectedTableItem?.context?.table_name,
      selectedDate.val
    );
    if (resTableAnalysis?.length) {
      setTableReadWriteAnalysis(
        resTableAnalysis.map((t: any) => ({
          value: t.rows_inserted ?? '',
          value1: t.rows_updated ?? '',
          value2: t.rows_deleted ?? '',
          value3: t.seq_rows_read ?? '',
          value4: t.idx_rows_read ?? '',
          time: t?._time,
          ...getNaChartValue([t.rows_inserted, t.rows_updated, t.rows_deleted])
        }))
      );
    }
  };

  const getTableAnalysisItem = async () => {
    setSSALoading(true);
    const schema_name = selectedTableItem?.schema_name?.[0]?.value;
    const table_name = selectedTableItem?.context?.table_name;
    if (table_name && schema_name && databaseId) {
      const resTableList: any = await getTableDBIdAndParamsAPI(
        restClient,
        selectedTableItem.context.table_name,
        schema_name,
        databaseId,
        extractApiKeyByHost(hostId, settings.hostsApiKey)
      );

      let extendColumns: any[] = [];
      if (resTableList?.table_insights?.metrics.length) {
        const columns = resTableList?.table_insights?.metrics?.find((m: any) => m?.metric_name === 'extend-columns');
        if (columns?.value.length) {
          extendColumns = columns.value.map((v: any) => ({
            'Column Name': v?.name,
            'Is Null': v?.isNull ? 'Yes' : 'No',
            'Data Type': v?.dataType,
            'Is PK': v?.isPrimaryKey ? 'Yes' : 'No'
          }));
        }
      }
      setTableReadWriteAnalysisItem({ ...resTableList, extendColumns });
      analyticsTrack('getTableAnalysisList Success', resTableList);
    }
    setSSALoading(false);
  };

  const getTableIndexesItem = async () => {
    const schema_name = selectedTableItem?.schema_name?.[0]?.value;
    const table_name = selectedTableItem?.context?.table_name;
    if (table_name && schema_name && databaseId) {
      const resTableList: any = await getTableIndexesAPI(
          restClient,
          table_name,
          schema_name,
          databaseId,
          extractApiKeyByHost(hostId, settings.hostsApiKey)
      );
      let tableIndexes: any[] = [];
      if (resTableList?.length) {
        tableIndexes = resTableList.map((v: any) => ({
          'Index Name': v?.name,
          'Columns or Expressions': v?.columns,
          ...(v?.hasOwnProperty('isUnique') && { 'Is Unique': v?.isUnique ? 'Yes' : 'No'}),
          ...(v?.indexType && { 'Index Type': v?.indexType}),
          ...(!isMySQL && {'Index Size': v?.indexSize}),
          'Scans 24H': v?.indexScansLast24Hours
          }))
        }
      setTableIndexes(tableIndexes);
    }
  };

  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        databaseId
      }));
    }
    if (hostId && databaseId) {
      getReports();
    }
  }, [hostId, databaseId]);

  useEffect(() => {
    if (selectedTableItem) {
      getTableAnalysisItem();
      getTableIndexesItem();
    }
  }, [selectedTableItem?.context?.table_name]);
  useEffect(() => {
    if (selectedTableItem?.context?.table_name) {
      getTableReadWriteAnalysis();
    }
  }, [selectedDate.val, selectedTableItem?.context?.table_name]);

  const getRowStyle = (params: any) => {
    const selectedSchemaName = selectedTableItem?.schema_name?.[0]?.value;
    if (
      params.data?.context?.table_name === selectedTableItem?.context?.table_name &&
      selectedSchemaName === params.data?.schema_name?.[0]?.value
    ) {
      return { background: colors?.new?.primary?.[150], fontWeight: 500 };
    }
  };

  const isMySQL: boolean = rdbms === RDBMS.mysql;

  return (
    <>
      <Container>
        <DataGridUIContainer className="ag-theme-material" height="90px">
          {isLoading ? (
            <TableLoader size={10} />
          ) : (
            <DataGridUI
              rowData={reportsState.rows}
              animateRows={true}
              columnDefs={columnDefs}
              suppressCellFocus={true}
              multiSortKey={'ctrl'}
              defaultColDef={defaultColDef}
              getRowStyle={getRowStyle}
              onRowClicked={(row: any) => {
                setSelectedItem(row.data);
              }}
            />
          )}
        </DataGridUIContainer>
        {!!reportsState.rows?.length && (
          <MainInnerDrawer direction="column">
            <BodyDrawer width="45vw">
              <TableHeader justify="space-between">
                <TitleDrawer h3 weight="600">
                  {isLoading ? (
                    <Skeleton animation="pulse" variant="text" width="200px" height={30} />
                  ) : (
                    `${selectedTableItem?.schema_name?.[0]?.value}.${selectedTableItem?.context?.table_name}`
                  )}
                </TitleDrawer>
                <Flex margin="8px 16px">
                  {isDebugMode && (
                    <Button variant="link" onClick={() => setDebugDrawerState(true)}>
                      <Typography h4 weight={600}>
                        Raw Info
                      </Typography>
                    </Button>
                  )}
                </Flex>
              </TableHeader>
              <Tabs value={tabState} onChange={handleTabChange} sx={{ width: 'calc(100% - 24px)', pt: 1, m: '0 12px' }}>
                {TableTabState.map((tabItem: string) => (
                  <Tab key={tabItem} id={`report-tab-${tabItem}`} label={tabItem} />
                ))}
              </Tabs>
              <TabPanel value={tabState} index={0} style={{ width: 'inherit' }}>
                <TabContainer>
                  <Flex>
                    <Flex margin="2px 6px 2px 0 ">
                      <Typography>Last schema update:</Typography>
                    </Flex>
                    <MainTitle weight="600">{getFormattedDate(tableAnalysisItem?.created_at)}</MainTitle>
                  </Flex>
                  <Flex fw>
                    {isSSALoading ? (
                      <TableLoader />
                    ) : (
                      <TableItem
                        data={tableAnalysisItem?.extendColumns || []}
                        emptyErrorMessage="No Data was found"
                        headHeight="190px"
                      />
                    )}
                  </Flex>
                </TabContainer>
              </TabPanel>

              <TabPanel value={tabState} index={1} style={{ width: 'inherit' }}>
                <TabContainer>
                  <Flex fw>
                    {isSSALoading ? (
                        <TableLoader />
                    ) : (
                        <TableItem
                            data={tableIndexes || []}
                            emptyErrorMessage="No Data was found"
                            headHeight="190px"
                        />
                    )}
                  </Flex>
                </TabContainer>
              </TabPanel>

              <TabPanel value={tabState} index={2} style={{ width: 'inherit' }}>
                <TabContainer>
                  <Flex fw justify={isMySQL ? 'start' : 'space-between'} align="start" margin="2px 0 0 0">
                    <Flex direction="column" align="start">
                      <MainTitle weight="500">Table size</MainTitle>
                      <br />
                      <Typography>
                        {selectedTableItem?.table_size?.length &&
                          byteMe(selectedTableItem?.table_size[selectedTableItem?.table_size?.length - 1]?.value, BYTE_SIZE.KB)}
                      </Typography>
                    </Flex>
                    <Flex direction="column" align="start" margin={isMySQL ? '0 0 0 48px' : ''}>
                      <MainTitle weight="500">Number of rows</MainTitle>
                      <br />
                      <Typography>
                        {selectedTableItem?.num_of_rows?.length
                          ? numberWithCommas(selectedTableItem.num_of_rows[selectedTableItem.num_of_rows.length - 1]?.value)
                          : '-'}
                      </Typography>
                    </Flex>
                    {!isMySQL && (
                      <>
                        <Flex direction="column" align="start">
                          <MainTitle weight="500">Dead rows</MainTitle>
                          <br />
                          <Typography>
                            {selectedTableItem?.dead_rows?.length
                              ? numberWithCommas(selectedTableItem.dead_rows[selectedTableItem.dead_rows.length - 1]?.value)
                              : '-'}
                          </Typography>
                        </Flex>
                        <Flex direction="column" align="start">
                          <MainTitle weight="500">TOAST Size</MainTitle>
                          <br />
                          <Typography>-</Typography>
                        </Flex>
                      </>
                    )}
                  </Flex>
                  <Flex margin="58px 0 0 0">
                    <MainTitle weight="500">Table Size </MainTitle>
                  </Flex>
                  <Flex fw direction="column" align="start">
                    <HChart
                      data={selectedTableItem?.table_size?.map((t: any) => ({
                        ...t,
                        ...getNaChartValue([t?.value])
                      }))}
                      type="bar"
                      showHeader={false}
                      valueName={['Table Size']}
                      formatFunc={(value: number) => byteMe(value, BYTE_SIZE.KB)}
                      isLoading={isSSALoading}
                      height={250}
                      reduceMargin={false}
                      dateFormatType={'dt'}

                    />
                  </Flex>
                </TabContainer>
              </TabPanel>
              <TabPanel value={tabState} index={3} style={{ width: 'inherit' }}>
                <TabContainer>
                  <Flex align="start" margin="0 0 16px 0">
                    <Flex margin="4px 12px 0 0">
                      <MainTitle weight="500">Last</MainTitle>
                    </Flex>
                    <Select
                      name=""
                      minWidth={'230px'}
                      isSorted={false}
                      value={selectedDate.val}
                      list={listSelectedDates}
                      onSelect={onSelectDate}
                    />
                  </Flex>
                  <MainTitle weight="500">Rows Read </MainTitle>
                  <HChart
                    data={tableReadWriteAnalysis.map((t: any) => ({
                      time: t.time,
                      value: t?.value3,
                      value1: t?.value4,
                      ...getNaChartValue([t?.value3])
                    }))}
                    type={'bar'}
                    valueName={['Rows Read', 'Index Read']}
                    formatFunc={(value: number) => `${toNumbersSuffix(value).num} ${toNumbersSuffix(value).suffix}`}
                    isLoading={isSSALoading}
                    height={250}
                    dateFormatType={'custom'}
                  />
                  <Flex margin="16px 0 0 0">
                    <MainTitle weight="500">Rows Write</MainTitle>
                  </Flex>
                  <Flex fw direction="column" align="center">
                    <HChart
                      data={tableReadWriteAnalysis}
                      valueName={['Insert', 'Update', 'Delete']}
                      formatFunc={(value: number) => `${toNumbersSuffix(value).num} ${toNumbersSuffix(value).suffix}`}
                      isLoading={isSSALoading}
                      height={250}
                      type={'bar'}
                      dateFormatType={'custom'}
                    />
                  </Flex>
                </TabContainer>
              </TabPanel>
              <TabPanel value={tabState} index={4} style={{ width: 'inherit' }}>
                <TabContainer>
                  <TableInsightsMapper insights={tableInsights[selectedTableItem?.context?.table_id] || []} />
                  </TabContainer>
               </TabPanel>
            </BodyDrawer>
          </MainInnerDrawer>
        )}
      </Container>
      <MDrawer onClose={() => setDebugDrawerState(false)} anchor="right" open={debugDrawerState}>
        <Flex direction="column" align="end">
          <Flex margin="8px 16px">
            <Close title="close-icon" onClick={() => setDebugDrawerState(false)} />
          </Flex>
          <CodeEditor
            isCopyValue
            readonly={true}
            width="45vw"
            height="92vh"
            value={JSON.stringify({ tableAnalysisItem, selectedTableItem, tableReadWriteAnalysis }, undefined, 2)}
          />
        </Flex>
      </MDrawer>
    </>
  );
};
