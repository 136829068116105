import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';
import {wrapNumbersInBold} from "../../../utils/automated-investgiation/utils";

type Params = {
    schemaName: string;
    tableName: string;
    pkColumns: string[];
};

const PrimaryKeyNumberOfColumnsInsight: React.FC<TableInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const { schemaName, tableName, pkColumns } = params as Params;
        return (
            <div>
                A Primary Key of a table should have 1-2 columns.
                The primary key of the table {schemaName}.{tableName} has {pkColumns.length} columns: {pkColumns.join(', ')}
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Increased complexity:</b></li>
                    A multi-column PK makes queries and joins more complex, as you need to reference all columns when
                    linking tables.
                    <li><b>Reduced performance:</b></li> Indexes on composite keys with many columns are larger and
                    slower to search, potentially impacting query speed.
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <ul>
                    <li>Introduce a surrogate key: Add a new single-column identifier (e.g., auto-incrementing
                        integer).
                    </li>
                    <li>Designate the surrogate key as the new primary key.</li>
                    <li>Create a unique constraint or index on the original multi-column key.</li>
                    <li>Update foreign key relationships to reference the new surrogate key.</li>
                </ul>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Primary Key Large Number Of Columns'} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                        Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default PrimaryKeyNumberOfColumnsInsight;