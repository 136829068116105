import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const MinWalSizeInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div><b>min_wal_size</b> in Postgres DB configuration sets the minimum amount of disk space reserved for the Write-Ahead Log (WAL). It ensures there is enough space for WAL writes during high-write periods, improving database reliability and recovery.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>Incorrect <b>min_wal_size</b> configuration can lead to write failures and potential data loss if set
                too low, or excessive disk space consumption if set too high.</div>
        )
    }

    const getRemediation = () => {
        return (
            <div>To address incorrect <b>min_wal_size</b> values, adjust the configuration gradually to ensure
                sufficient space for WAL writes without excessive resource consumption. Monitor disk space usage closely
                and consult documentation for recommended values.</div>
        )
    }
    return (
        <InsightsAccordion title={'min_wal_size'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                    <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default MinWalSizeInsight;