import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const InnodbDoubleWriteInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>The <code style={{backgroundColor: '#E0E0E0'}}>innodb_doublewrite</code> setting ensures data integrity by
                writing data twice: first to a doublewrite buffer and then to the actual data files. This helps protect
                against data corruption during crashes.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div><b>Data Loss:</b> Disabling <code style={{backgroundColor: '#E0E0E0'}}>innodb_doublewrite</code> (setting
                it to OFF) increases the risk of data corruption. Without it, a crash during a write operation can
                result in undetected partial writes, leading to irreparable table corruption and data loss. The minimal
                performance gain isn't worth this risk, especially in production environments where data integrity is
                critical.</div>
        )
    }

    const getRemediation = () => {
        return (
            <div>Enable the setting by adding or updating the following line in your MySQL configuration file (<code
                style={{backgroundColor: '#E0E0E0'}}>my.cnf</code> or <code style={{backgroundColor: '#E0E0E0'}}>my.ini</code>):
                <br/>
                <div style={{backgroundColor: '#f4f4f4', padding: 5, borderRadius: 5, border: '1px solid #ddd'}}>
                    <code>innodb_doublewrite=ON</code></div>
                <br/>
                <div>After making this change, restart your MySQL service to apply the configuration. You can do this
                    either through your cloud provider's console or by running the following command:
                </div>
                <div style={{backgroundColor: '#f4f4f4', padding: 5, borderRadius: 5, border: '1px solid #ddd'}}><code>sudo
                    systemctl restart mysql
                </code></div>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'innodb_doublewrite'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default InnodbDoubleWriteInsight