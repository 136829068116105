import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';
import TableItem from "../../Table/TableItem";

type Params = {
    columns: string,
    referencedColumns: string,
    fkName: string,
}[]

const FKDataTypeMatchPKInsight: React.FC<TableInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const fkData = params as Params;
        return (
            <div>
                The data type(s) of the PK should match the data types of the FK
                <TableItem
                    headHeight={'320px'}
                    data={
                            fkData?.map((foreignKeyData) => {
                                return {
                                    ['Columns']: foreignKeyData.columns,
                                    ['Referenced Columns']: foreignKeyData.referencedColumns,
                                    ['FK Name']: foreignKeyData.fkName,
                            }
                        })
                    }
                />
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                Performance: Matching data types between primary and foreign keys prevents the need for data type
                conversions during query
                execution. This avoids potential inefficiencies caused by converting data types and ensures that indexes
                can be effectively
                utilized.
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                Change the data types of the Foreign Key to be exactly as the Primary Key's data types.
            </div>
        )
    }

    return (
        <InsightsAccordion title={'FK Data Types Does not Match the PK'} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default FKDataTypeMatchPKInsight;