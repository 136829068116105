import {SQLExplainNode} from "./dbParserTypes";
import { Parser } from 'node-sql-parser';

const parser = new Parser();
const opt = {
  database: 'MySQL'
}

export const parseSQLToAST =(SQLQuery: string) => {
  try {
    return parser.astify(SQLQuery, opt);
  } catch (error) {
    throw new Error(`Error parsing SQL to AST: ${error}`);
  }
}

export const extractAliasMap = (ast: any): any[] => {
  const result: any[] = [];
  const seen = new Set();

  function traverse(currentObj: any) {
    if (Array.isArray(currentObj)) {
      currentObj.forEach(traverse);
    } else if (currentObj && typeof currentObj === 'object') {
      if (currentObj.hasOwnProperty('from') && Array.isArray(currentObj.from)) {
        currentObj.from.forEach((item: any) => {
          if (item.hasOwnProperty('db') && item.hasOwnProperty('table')) {
            const key = JSON.stringify(item);
            if (!seen.has(key)) {
              const aliasEntry = {
                db: item.db,
                table: item.table,
                as: item.as || null,
                join: item.join || null,
                on: item.on || null,
              };

              result.push(aliasEntry);
              seen.add(key);
            }
          }
          traverse(item);
        });
      }
      Object.values(currentObj).forEach(traverse);
    }
  }

  traverse(ast);
  return result;
};

export const flattenObject = (obj: any, parentKey = '', result: Record<string, any> = {}): Record<string, any> => {
  for (let [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          flattenObject(item, `${newKey}[${index}]`, result);
        } else {
          result[`${newKey}[${index}]`] = item;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      flattenObject(value, newKey, result);
    } else {
      result[newKey] = value;
    }
  }

  return result;
};



const capitalizeWords = (str: string) => {
  return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

export const enrichNodes = (nodes: SQLExplainNode[], aliasMap: any) => {
  if (!aliasMap || !nodes) return;

  let aliasMapCopy = [...aliasMap];
  nodes.forEach(node => {
    if (node.data.type === 'table') {
      const labelParts = node.data.label.split(" ");
      const alias = labelParts.length > 1 ? labelParts[1] : null;

      if (alias) {
        aliasMapCopy.forEach((entry: any) => {
          if (entry.as === alias) {
            const realTableName = entry.table;
            node.data.label = `Table ${capitalizeWords(realTableName)} (${alias})`;
          }
        });
      }
    }

    if (node.data.type === 'join') {
      for (let i = aliasMapCopy.length - 1; i >= 0; i--) {
        const entry = aliasMapCopy[i];
        const joinCondition = entry.on;

        if (joinCondition) {
          const leftTableAlias = joinCondition.left.table;
          const rightTableAlias = joinCondition.right.table;

          const leftNode = nodes.find(node => node.data.label.includes(`(${leftTableAlias})`));
          const rightNode = nodes.find(node => node.data.label.includes(`(${rightTableAlias})`));

          if (leftNode && rightNode) {
            if (node.data.label === 'Join') {
              node.data.label = `${capitalizeWords(entry.join)} (${leftTableAlias} = ${rightTableAlias})`;
            }
            aliasMapCopy.splice(i, 1);
            break;
          }
        }
      }
    }
  });
};

type ReplacementMap = Record<string, string>;

export const replaceSQLKeywords = (sqlQuery: string): string => {
  let modifiedQuery = sqlQuery;
  const replacements: ReplacementMap = {
    DISTINCTROW: 'DISTINCT',
  };

  for (const [oldWord, newWord] of Object.entries(replacements)) {
    const regex = new RegExp(`\\b${oldWord}\\b`, 'gi');
    modifiedQuery = modifiedQuery.replace(regex, newWord).replace(/\s*\.\s*/g, '.');
  }

  return modifiedQuery;
}
