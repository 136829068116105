import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const MaxParallelWorkersInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div><b>max_parallel_workers</b> in Postgres DB configuration specifies the maximum number of parallel
                worker processes that can be active simultaneously to execute parallel queries. Parallel workers allow
                for parallel execution of queries, improving query performance for suitable workloads.</div>
        )
    }

    const getImpact = () => {
        return (
            <div>Incorrect <b>max_parallel_workers</b> configuration can impact query performance and resource
                utilization. If set too low, it may limit the number of parallel worker processes, potentially leading
                to slower execution of parallelizable queries. If set too high, it can consume excessive system
                resources, causing resource contention and potential performance degradation.</div>
        )
    }

    const getRemediation = () => {
        return (
            <div>To address incorrect max_parallel_workers values, adjust the configuration based on the system's
                capabilities and workload requirements. Monitor query performance and resource utilization closely.
                Consider the nature of queries and the available system resources. Consult documentation and conduct
                performance testing to determine the optimal <b>max_parallel_workers</b> value for the specific database
                environment.</div>
        )
    }
    return (
        <InsightsAccordion title={'max_parallel_workers'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default MaxParallelWorkersInsight;