import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

type InnodbBufferPoolSizeInsightProps = {
    innoDBBufferPoolInstance: number;
    innoDBBufferPoolSize: number;
}
const InnodbBufferPoolInstancesInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const { innoDBBufferPoolInstance, innoDBBufferPoolSize } = params as InnodbBufferPoolSizeInsightProps;
        return (
            <div>
                The <code style={{backgroundColor: '#E0E0E0'}}>innodb_buffer_pool_instances</code> parameter in MySQL
                splits the InnoDB buffer pool into multiple instances to reduce contention and improve performance,
                especially with large buffer pools. To ensure efficiency, each buffer pool instance should ideally be at
                least 1GB.
                <br/>
                Current configuration:
                <ul>
                    <li><code style={{backgroundColor: '#E0E0E0'}}>innodb_buffer_pool_instances</code>:
                        {innoDBBufferPoolInstance}
                    </li>
                    <li><code style={{backgroundColor: '#E0E0E0'}}>innodb_buffer_pool_size</code>:
                        {innoDBBufferPoolSize}
                    </li>

                </ul>
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                Setting <code style={{backgroundColor: '#E0E0E0'}}>innodb_buffer_pool_instances</code> Too Low (e.g., 1):

                <b>Increased Contention:</b> With only one buffer pool instance, all database threads compete for access
                to a single memory area, which can lead to contention and bottlenecks, especially in high-concurrency
                environments.
                <br/>
                Setting <code style={{backgroundColor: '#E0E0E0'}}>innodb_buffer_pool_instances</code> Too High:

                <b>Fragmented Memory:</b> If the number of instances is too high relative to the buffer pool size, each
                instance becomes very small. This fragmentation can lead to inefficient caching, as each instance may
                not hold enough data to be effective.
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b>Assess Current Configuration:</b>
                <ul>
                    <li>Review the current innodb_buffer_pool_size and innodb_buffer_pool_instances settings.</li>
                    <li>Identify if the number of instances is too low (e.g., set to 1) or too high relative to the
                        buffer pool size.
                    </li>
                </ul>
                <br/>
                <b>Adjust Buffer Pool Instances:</b>
                <ul>
                    <li>
                        <ul>
                            Set innodb_buffer_pool_instances so that each instance is approximately 1GB. For example:

                            <li>If the buffer pool size is 8GB, set innodb_buffer_pool_instances to 8.</li>

                            <li>If the buffer pool size is 12GB, set it to 8 or 12, depending on your workload's
                                concurrency needs.
                            </li>

                            <li>For buffer pools larger than 32GB, set innodb_buffer_pool_instances to 32, as this is
                                the maximum effective number of instances.
                            </li>
                        </ul>
                    </li>
                    <li>Monitor Performance:

                        After making changes, monitor MySQL performance, focus on the cache hit ratio and overall
                        queries duration.
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'innodb_buffer_pool_instances'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default InnodbBufferPoolInstancesInsight