import React, {ReactNode, useRef, useState} from 'react';
import styled from 'styled-components';
import Typography from "../../core/Typography";
import {SeverityLabel} from "../SeverityLabel/SeverityLabel";
import {
    AccordionContainer,
    AccordionHeader,
    ArrowIcon,
    Content,
    RightSideContainer,
    Title
} from "./InsightsAccordion.styled";

type AccordionProps = {
    defaultOpen?: boolean;
    title: string;
    severity: number;
    children?: ReactNode;
};

const InsightsAccordion = ({ title, defaultOpen, severity, children }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const contentRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState('0px');

    React.useEffect(() => {
        if (isOpen) {
            setMaxHeight(`${contentRef?.current?.scrollHeight}px`);
        } else {
            setMaxHeight('0px');
        }
    }, [isOpen]);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <AccordionContainer>
            <AccordionHeader onClick={toggleAccordion}>
                <Title>
                    <Typography h3>{title}</Typography>
                </Title>
                <RightSideContainer>
                    <SeverityLabel priority={severity}/>
                    <ArrowIcon isOpen={isOpen}/>
                </RightSideContainer>
            </AccordionHeader>
            <div
                style={{
                    maxHeight: maxHeight,
                    transition: 'max-height 0.4s ease-in-out',
                    overflow: 'hidden'
                }}
                ref={contentRef}
            >
                    <Content>
                        {children}
                    </Content>
                </div>
        </AccordionContainer>
);
};

export default InsightsAccordion;
