import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    tableName: string,
    fkData: {
        name: string,
        columnWithoutIndex: string
    }[]
};

const FKMissingIndexInsight: React.FC<TableInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const { fkData } = params as Params;
        return (
            <div>
                The Foreign Keys: {fkData.map(fk => fk.name).join(', ')} Don't Have an Index
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Slow Queries:</b>Database operations involving the foreign key, such as joins, lookups, and
                        updates, will be slower, especially as the table grows in size.
                    </li>

                    <li><b>Inefficient Joins:</b>Queries that join tables on the foreign key will require full table
                        scans instead of efficient index lookups, leading to longer execution times.
                    </li>

                    <li><b>Delayed Inserts and Deletes:</b>Without an index, enforcing referential integrity during
                        insert and delete operations becomes more time-consuming, as the database must perform full
                        scans to check for valid references.
                    </li>

                    <li><b>Increased Lock Contention:</b>Longer-running queries can lead to increased lock contention,
                        where multiple operations compete for access to the same data, causing delays and potential
                        deadlocks.
                    </li>
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        const {tableName, fkData} = params as Params;
        return (
            <div>
                Create Missing Indexes:
                <br/>
                <pre>
                {fkData.map((fk, idx: number) => {
                    return <div>
                        <code>CREATE INDEX {fk.name} ON {tableName} ({fk.columnWithoutIndex});</code>
                    </div>
                })}
                </pre>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Foreign Key Missing an Index'} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default FKMissingIndexInsight;