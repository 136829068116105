import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

type InnodbBufferPoolSizeInsightProps = {
    innoDBBufferPoolSize: number;
    innoDBPercentageOfRAM: number;
}
const InnodbBufferPoolSizeInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const { innoDBBufferPoolSize, innoDBPercentageOfRAM } = params as InnodbBufferPoolSizeInsightProps;
        return (
            <div>The value of innodb_buffer_pool_size is {innoDBBufferPoolSize} ({innoDBPercentageOfRAM}% of RAM).
                <br/>
                This configuration setting controls the size of the buffer pool where InnoDB caches data and indexes.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <b>Set Too Low:</b>
                <ul>
                    <li><b>Insufficient Caching:</b> If innodb_buffer_pool_size is set too low, MySQL cannot effectively
                        cache data and indexes. This leads to more disk I/O operations as data needs to be read from
                        disk rather than from memory, significantly slowing down database performance.
                    </li>

                    <li><b>Increased Latency:</b> Applications interacting with the database may experience higher
                        latency due to frequent disk reads, especially under high load or when dealing with large
                        datasets.
                    </li>
                </ul>
                <br/>
                <b>Set Too High:</b>
                <ul>
                    <li><b>Memory Contention:</b></li>
                    If innodb_buffer_pool_size is set too high, it can consume excessive system memory, leaving
                    insufficient RAM for other processes, including the operating system, and leading to swapping, which
                    severely degrades performance since disk I/O is much slower than accessing data in RAM.
                    Additionally, each new database connection to MySQL requires memory for session-specific data
                    structures, such as buffers, temporary tables, and query results. A large innodb_buffer_pool_size
                    may result in inadequate free memory to efficiently handle these additional connections.
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b>Start with 50% of RAM:</b> Set innodb_buffer_pool_size to about 50% of your server's total RAM to
                balance memory usage between MySQL and other system processes.
                <br/>
                <b>Monitor Cache Hit Ratio:</b> Regularly check the cache hit ratio. If it’s consistently 95% or higher,
                your buffer pool is sufficient, and data retrieval is efficiently handled from memory.
                <br/>
                <b>Optimize High-Impact Queries:</b> Use the "Top Queries" widget to find queries that read many rows
                and optimize them. This helps reduce unnecessary data load on the buffer pool.
                <br/>
                <b>Scale Up if Needed:</b> If the cache hit ratio is often below 95%, consider adding more RAM to your
                server to increase innodb_buffer_pool_size and improve performance.
            </div>
        )
    }

    return (
        <InsightsAccordion title={'innodb_buffer_pool_size'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default InnodbBufferPoolSizeInsight