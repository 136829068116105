import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const CheckpointCompletionTargetInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>This parameter defines a percentual of checkpoint_timeout as a target to write the CHECKPOINT data on
                the disk. The recommended value of <b>checkpoint_completion_target</b> is 0.9.</div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                Setting a low value causes the checkpoint to complete faster. This results in a higher rate of I/O during the checkpoint followed by a period of less I/O between the checkpoint completion and the next scheduled checkpoint
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>Configure the value to 0.9</div>
        )
    }
    return (
        <InsightsAccordion title={'checkpoint_completion_target'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default CheckpointCompletionTargetInsight;