import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    numberOfIndexes: number;
    numberOfColumns: number;
};

const NumberOfIndexesInsight: React.FC<TableInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const { numberOfIndexes, numberOfColumns } = params as Params;
        return (
            <div>
                The table has <b>{numberOfColumns}</b> of columns and <b>{numberOfIndexes}</b> of indexes.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                High number of indexes can slow down write operations and use more disk space.
                Unused indexes still consume disk space and may not improve performance.
                Queries may not always use indexes, leading to slower performance.
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                Remove unnecessary or duplicate indexes.
                Analyze and optimize queries to reduce reliance on indexes.
                Use partial indexes or index-only scans where appropriate to reduce disk space usage.
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Number of Indexes'} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default NumberOfIndexesInsight;