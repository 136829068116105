import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const StatementTimeoutInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>
                The configuration parameter statement_timeout controls the maximum duration a statement can run before it is automatically terminated by the server.<br/><br/>
                <b>Default Behavior</b>: When statement_timeout is set to 0 (zero), it means there is no enforced timeout for statements. Statements will be allowed to run indefinitely until they complete or until manually terminated.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                When statement_timeout is configured to 0:<br/>
        <ul><li><b>Unrestricted Query Execution</b>: Queries can run indefinitely, potentially leading to long-running operations that consume significant resources, degrade system performance, and negatively impact user experience and maintenance efforts.</li></ul>
        When statement_timeout is set to a short duration, such as 300 ms:<br/>
        <ul><li><b>Frequent Query Termination</b>: Many queries, especially complex ones, may not complete within the short timeout, leading to frequent terminations, failed transactions, and potentially degraded application functionality and user experience.</li></ul>
        When statement_timeout is set to a long duration, such as 30 minutes:<br/>
        <ul><li><b>Insufficient for Very Long Queries</b>: Queries intended to run longer than the specified timeout will still be terminated, potentially disrupting essential operations and leading to incomplete transactions or failed long-running analytical tasks.</li></ul>
    </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b>Set a Reasonable Timeout Value</b><br/>
                Choose a timeout value appropriate for your application's query complexity and workload. For example, to
                set a timeout of 30 minutes:<br/>
                <code>-- Set statement_timeout to 30 minutes (1800000 milliseconds) <br/>
                    ALTER SYSTEM SET statement_timeout = '1800000';</code><br/><br/>
                <b>Restart PostgreSQL Server</b><br/>
                <b>Log statement termination events</b><br/>
                If <code>log_min_error_statement</code> is set to ERROR or lower, the statement that timed out will also
                be logged.<br/>
                <code>ALTER SYSTEM SET log_min_error_statement = 'ERROR';</code><br/><br/>
                <b>Monitor and Adjust Timeout as Needed</b><br/>
                Monitor query performance and adjust statement_timeout based on query complexity and workload patterns.
                Use tools like pg_stat_activity to identify sessions affected by timeouts:<br/>
                <code>SELECT pid, usename, query, state<br/>
                    FROM pg_stat_activity<br/>
                    WHERE state = 'active' AND query_start &lt; NOW() - interval '30 seconds';
                </code>
            </div>

        )
    }

    return (
        <InsightsAccordion title={'statement_timeout'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default StatementTimeoutInsight;