import React from 'react';
import styled from 'styled-components';
import Flex from '../Flex';

interface MetricTooltip {
    data: {
        value: number | string;
        metric: string;
        weight: number;
        values: {
            good: { min: number; max: number; points: number };
            moderate: { min: number; max: number; points: number };
            bad: { min: number; max: number; points: number };
        };
    }[];
}

export default function MetricTooltip({ data }: MetricTooltip) {

    const totalWeight = data?.reduce((total, metric) => total + metric.weight, 0);

    const getClassification = (value: number | string, ranges: any) => {
        if (value >= ranges.good.min && value <= ranges.good.max) {
            return 'good';
        } else if (value >= ranges.moderate.min && value <= ranges.moderate.max) {
            return 'moderate';
        } else {
            return 'bad';
        }
    };

    const getColor = (classification: string) => {
        switch (classification) {
            case 'good':
                return '#66E2A3';
            case 'moderate':
                return '#FFE066';
            case 'bad':
                return '#F57385';
            default:
                return '#E4E5F8';
        }
    };

    const formatValue = (value: number | string | null | undefined): string | null => {
        if (typeof value === 'number') {
            return value.toFixed(1);
        }

        if (typeof value === 'string') {
            let numericValue: number | null = null;

            if (value.includes('%')) {
                numericValue = parseFloat(value.replace('%', ''));
            } else {
                numericValue = parseFloat(value);
            }

            if (!isNaN(numericValue)) {
                return numericValue.toFixed(1);
            }
        }

        return null;  // Return null if value is not valid for parsing
    };


    const calculateScore = (classification: string, ranges: any, weight: number) => {
        const points = ranges[classification]?.points || 0;
        return points * (weight / totalWeight);
    };

    return (
        <TooltipContainer>
            {data?.map((metricData, index) => {
                const classification = getClassification(metricData.value, metricData.values);
                const color = getColor(classification);
                const score = calculateScore(classification, metricData.values, metricData.weight);

                return (
                    <MetricContainer key={index} color={color}>
                        <MetricDetails>
                            <MetricName>{metricData.metric}</MetricName>
                            <MetricValue>{formatValue(metricData.value)}</MetricValue>
                            <MetricWeight>Weight: {metricData.weight}</MetricWeight>

                        <MetricScore>Score: {(score* 100).toFixed(1)}</MetricScore>
                        </MetricDetails>
                    </MetricContainer>
                );
            })}
        </TooltipContainer>
    );
}

const TooltipContainer = styled(Flex)`
    flex-direction: column;
    width: 350px;
`;

const MetricContainer = styled.div<{ color: string }>`
    display: flex;
    justify-content: space-between;
    background-color: ${({ color }) => color};
    border-radius: 5px;
    margin-bottom: 5px;
    flex-direction: column;
    width: 100%
    
`;

const MetricDetails = styled.div`
    display: flex;
`;

const MetricName = styled.span`
    font-weight: bold;
    margin-right: 10px;
`;

const MetricValue = styled.span`
    margin-right: 10px;
`;

const MetricWeight = styled.span`
  margin-right: 10px;
`;

const MetricScore = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;
