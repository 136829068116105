import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const IdleInTransactionSessionTimeoutInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>
                <p>
                    The <b>idle_in_transaction_session_timeout</b> parameter sets a limit on how long a session can stay
                    idle within a transaction before being terminated by the server. This is crucial for maintaining
                    database health by preventing long-lived idle transactions that can lead to various performance and
                    maintenance issues. The default value is 0. </p>
                <p> A value of 0 means there is no timeout, allowing idle transactions to remain open indefinitely. </p>
                <p> A timeout value between 1 and 100 milliseconds is likely too short, causing excessive termination of
                    transactions. </p>
                <p> A timeout value of 3,600,000 milliseconds (1 hour) may allow idle transactions to hold resources and
                    locks for excessively long periods.</p>
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Resource Consumption:</b> Idle transactions consume resources, such as memory and
                        connections, which can degrade the performance of the database.
                    </li>
                    <li><b>Lock Contention:</b> Idle transactions often hold locks on database objects, which can block
                        other transactions, leading to delays and potential deadlocks.
                    </li>
                    <li><b>Table Bloat:</b> Long-running idle transactions can prevent the database from reclaiming
                        space efficiently, resulting in table bloat and increased maintenance costs.
                    </li>
                    <li><b>Application Issues:</b> Transactions left open indefinitely might be symptomatic of
                        application bugs, leading to unpredictable behavior and resource exhaustion.
                    </li>
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        if (severity === 4) { // low
            return (
                <div>If the <b>idle_in_transaction_session_timeout</b> value is set to a very high value, such as
                    3,600,000 milliseconds (1 hour), it may allow idle transactions to hold resources and locks for
                    excessively long periods. This can lead to significant resource consumption, increased lock
                    contention, and potential table bloat. It is recommended to reduce the timeout to a more reasonable
                    value, such as a few minutes, to ensure transactions do not remain idle for too long, thus improving
                    resource management and database performance.<br/><br/>
                    <code>ALTER SYSTEM SET idle_in_transaction_session_timeout to 600000;</code>
                </div>
            )
        } else if (severity === 3) { //medium
            return (
                <div>If the <b>idle_in_transaction_session_timeout</b> value is set to 0, it means there is no timeout,
                    allowing idle transactions to remain open indefinitely. This can lead to resource consumption, lock
                    contention, and potential table bloat. It is advisable to set a reasonable timeout value to prevent
                    these issues and ensure better resource management and database performance.<br/><br/>
                    <code>ALTER SYSTEM SET idle_in_transaction_session_timeout to 600000;</code>
                </div>
            )
        } else if (severity === 2) { // high
            return (
                <div>If the <b>idle_in_transaction_session_timeout</b> value is set between 1 and 100 milliseconds, this
                    duration might be too short, causing excessive termination of transactions. It's advisable to review
                    and
                    adjust the timeout to a more appropriate value that balances preventing long-lived idle transactions
                    and
                    allowing legitimate transactions to complete.<br/><br/>
                    <code>ALTER SYSTEM SET idle_in_transaction_session_timeout to 600000;</code>
                </div>
            )
        }
    }
        return (
            <InsightsAccordion title={'idle_in_transaction_session_timeout'} severity={severity} defaultOpen>
                <Container justify={'flex-start'}>
                    <FlexFullWrapper justify="start" align="start" margin="8px 0">
                        <IconWrapper>
                            <InsightsDetailsIcon width={24}/>
                        </IconWrapper>
                        <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default IdleInTransactionSessionTimeoutInsight;