import { KyInstance } from 'ky/distribution/types/ky';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { SnackbarStateI } from '../../atoms/snackbar.atom';
import { SnackbarSeverityE } from '../../enums/snackbar-severity.enum';
import { meanBy } from "../ObservabilityDashboard/utils";

const timeFrame = '1h';

export const getFreeMemoryAPI = async (
  restClient: KyInstance,
  hostId = '',
  apiKey = ''
): Promise<any[]> => {
  try {
    const startTime = dayjs().subtract(24, 'h').toISOString();
    const endTime = new Date().toISOString();
    const resMemory: any[] = await restClient
      .get('reports/overview/host/freeable-memory', {
        searchParams: { hostId, startTime, endTime, timeFrame },
        headers: { 'x-api-key': apiKey }
      })
      .json();

    return resMemory;
  } catch (err: any) {
    const body = err?.response;

    Sentry.captureException(new Error(`Error-getFreeMemoryAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });

    return [];
  }
};
export const getCPU_API = async (restClient: KyInstance, hostId = '', apiKey = ''): Promise<any[]> => {
  try {
    const startTime = dayjs().subtract(24, 'h').toISOString();
    const endTime = new Date().toISOString();
    const resCPU: any[] = await restClient
      .get('reports/overview/host/cpu-utilization', {
        searchParams: { hostId, startTime, endTime, timeFrame },
        headers: { 'x-api-key': apiKey }
      })
      .json();

    return resCPU;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getCPU_API-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return [];
  }
};

export const getHostsByApiKey = async (restClient: KyInstance, apiKey = ''): Promise<any[]> => {
  try {
    const resHost: any[] = await restClient
        .get('hosts/by-api-key', {
          headers: {
            'x-api-key': apiKey,
          }
        })
        .json();

    return resHost;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getHostsByApiKey-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return [];
  }
};
export const getMonitoringHostAPI = async (restClient: KyInstance, apiKey = ''): Promise<any[]> => {
  try {
    const resHost: any[] = await restClient
      .get('hosts', {
        headers: {
          'x-api-key': apiKey,
        }
      })
      .json();

    return resHost;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getMonitoringHostAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return [];
  }
};

export const deletedHostAPI = async (
  restClient: KyInstance,
  defaultApiKey = '',
  hostId: number,
  setSnackbarS: any
): Promise<boolean> => {
  try {
    await restClient
      .delete(`host`, {
        headers: { 'x-api-key': defaultApiKey },
        searchParams: { hostId }
      })
      .json();

    setSnackbarS((oldSnackbarState: SnackbarStateI) => {
      return {
        ...oldSnackbarState,
        open: true,
        severity: SnackbarSeverityE.success,
        text: `The host has been deleted successfully`
      };
    });
    return true;
  } catch (err: any) {
    setSnackbarS((oldSnackbarState: SnackbarStateI) => {
      return {
        ...oldSnackbarState,
        open: true,
        severity: SnackbarSeverityE.warning,
        text: `Make sure to stop the Metis Agent or configure it not to monitor that host.`,
        descriptions: JSON.stringify(err)
      };
    });
    const body = err?.response;
    Sentry.captureException(new Error(`Error-deletedHostAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return false;
  }
};

export const getDBsScoreAPI = async(  restClient: KyInstance,
                                        apiKey = '',
                                        hostName: string): Promise<any> => {
  try {
    const dbData: any[] = await restClient
        .get('db-details/health', {
          searchParams: { hostName },
          headers: { 'x-api-key': apiKey }
        })
        .json()

    if (!dbData.length){
      return 'N/A'
    }
    const data = dbData.map(obj => obj.data).filter(obj => obj?.healthScore !== undefined);
    return meanBy(data, 'healthScore') || 'N/A';
  }
  catch(err: any){
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getHostDBDataAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return undefined;
  }
}

export const getDbSizeCompareApi = async (restClient: KyInstance, apiKey: string | undefined, host1Id: number, host2Id: number): Promise<any> => {
  try {
    const resConfig: any = await restClient.get(`compare/size?hostId1=${host1Id}&hostId2=${host2Id}`, {headers: {'x-api-key': apiKey}}).json();

    return resConfig;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getConfigCompareAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
}

export const getHardwareCompareAPI = async (restClient: KyInstance, apiKey: string | undefined, host1Id: number, host2Id: number): Promise<any> => {
  try {
    const resConfig: any = await restClient.get(`compare/hardware?hostId1=${host1Id}&hostId2=${host2Id}`, {headers: {'x-api-key': apiKey}}).json();

    return resConfig;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getConfigCompareAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
}


export const getConfigCompareAPI = async (restClient: KyInstance, apiKey: string | undefined, host1Id: number, host2Id: number): Promise<any> => {
  try {
    const resConfig: any = await restClient.get(`compare/configs?hostId1=${host1Id}&hostId2=${host2Id}`, {headers: {'x-api-key': apiKey}}).json();

    return resConfig;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getConfigCompareAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
}

export const getQueriesCompareAPI = async (restClient: KyInstance, apiKey: string | undefined, host1Id: number, host2Id: number): Promise<any> => {
  try {
    const resQueries: any = await restClient.get(`compare/queries?hostId1=${host1Id}&hostId2=${host2Id}`, {headers: {'x-api-key': apiKey}}).json();
    return resQueries;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getConfigCompareAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
}

export const getIndexesCompareAPI = async (restClient: KyInstance, apiKey: string | undefined, host1Id: number, host2Id: number): Promise<any> => {
  try {
    const resQueries: any = await restClient.get(`compare/indexes?hostId1=${host1Id}&hostId2=${host2Id}`, {headers: {'x-api-key': apiKey}}).json();
    return resQueries;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getConfigCompareAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
}

export const getTablesCompareAPI = async (restClient: KyInstance, apiKey: string | undefined, host1Id: number, host2Id: number): Promise<any> => {
  try {
    const resQueries: any = await restClient.get(`compare/tables?hostId1=${host1Id}&hostId2=${host2Id}`, {headers: {'x-api-key': apiKey}}).json();
    return resQueries;
  } catch (err: any) {
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getConfigCompareAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
}

export const getHostHealthAPI = async(restClient: KyInstance,
                                      apiKey: string | undefined,
                                      hostName: string): Promise<{ healthScore: number | undefined , metricCalculation : any }> => {
  try {
    const data: any = await restClient
        .get('hosts/health', {
          searchParams: { host: hostName },
          headers: {'x-api-key': apiKey}
        })
        .json()
    return data;
  }
  catch(err: any){
    const body = err?.response;
    Sentry.captureException(new Error(`Error-getHostHealth-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {
      healthScore: undefined
    };
  }
}

