import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const TrackIOTimingInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>
                The <code>track_io_timing</code> configuration settings, when enabled, tracks the amount of time the database spends performing input/output (I/O) operations.<br/>
        The current value of this setting is off.
    </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                When track_io_timing is turned off:<br/>
        <ul><li><b>Lack of Detailed I/O Performance Metrics</b>: The database will not collect detailed timing information for I/O operations, which makes it difficult to diagnose and optimize I/O performance issues.</li>
            <li><b>Limited Performance Analysis</b>: Without detailed I/O timing information, it is challenging to pinpoint the root causes of performance bottlenecks related to disk I/O, such as slow queries caused by high read/write latency.</li></ul>
        Notice! Enabling <code>track_io_timing</code> may introduce a very small performance overhead due to the additional collection of I/O timing statistics. This overhead is generally minimal but could be noticeable in high-performance environments where every bit of performance is critical.
    </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <ul><li><b>Enable</b> the configuration setting: <code>track_io_timing</code><br/>
                    <code>ALTER SYSTEM SET track_io_timing = 'on';</code></li><br/>
        <li><b>Restart</b> Postgres</li>
        <li><b>Verify</b> the value had changed<br/>
        <code>SHOW track_io_timing;</code></li><br/>
        <li><b>Monitoring the Impact on Performance</b>: Once track_io_timing is enabled, you can monitor the performance impact and gather I/O timing statistics.<br/>You can use tools such as <a href="https://www.postgresql.org/docs/current/pgtesttiming.html">pg_test_timing</a> to measure the overhead of timing on your system, or utilize Metis to observe changes in query times.</li></ul>
    </div>
        )
    }

    return (
        <InsightsAccordion title={'track_io_timing'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default TrackIOTimingInsight;