import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const WorkMemInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div><b>work_mem</b> in Postgres DB configuration determines the amount of memory allocated for each query's
                internal sort operations and hash tables, improving performance by reducing disk I/O.</div>
        )
    }

    const getImpact = () => {
        return (
            <div>Incorrect <b>work_mem</b> configuration can lead to increased disk I/O and temporary file usage if set
                too low, or excessive memory usage and potential performance degradation if set too high.</div>
        )
    }

    const getRemediation = () => {
        return (
            <div>To address incorrect <b>work_mem values</b>, gradually adjust the configuration to find the optimal
                balance between memory utilization and sorting/hashing efficiency. Monitor performance closely and
                consult documentation for recommended values.</div>
        )
    }

    return (
        <InsightsAccordion title={'work_mem'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default WorkMemInsight;