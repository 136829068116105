import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

type Params = {
    logMinDurationStatement: number;
    X: number;
    Y: number;
    Z: number;
    A: number;
    B: number;
    C: number;
};

const LogMinDurationStatementInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const { logMinDurationStatement } = params as Params;
    const getDetails = () => {
            return (
                <div>
                    The <code>log_min_duration_statement</code> parameter is used to log the duration of SQL queries. It
                    controls the logging of the duration of each completed statement that takes longer than the
                    specified number of milliseconds. Here are the key details:
                    <br/>
                    This configuration can be set to:
                    <ul>
                        <li><b>-1</b>: Disables logging of query durations (default)</li>
                        <li><b>0</b>: Logs all statements and their durations.</li>
                        <li><b>Positive integer</b>: Logs statements that exceed the specified number of milliseconds.
                        </li>
                    </ul><br/>
                    The current value is <code>{logMinDurationStatement}</code>.
                </div>
            )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Logging Too Many Queries</b>: Setting the value too low (e.g., 0 or a very small number) can result in excessive logging, capturing almost all queries. This can lead to an overwhelming amount of log data, making it difficult to identify truly problematic queries amidst the noise.</li><br/>
                <li><b>Filling the Storage</b>: Excessive logging can rapidly consume disk space allocated for log files. In severe cases, this can lead to a full disk, causing PostgreSQL to crash or behave unexpectedly.</li><br/>
        <li><b>Performance</b>: Continuous high log volume can also degrade overall database performance, as writing to log files competes with regular database operations for disk I/O resources.</li><br/>
        <li><b>Cost</b>: Cloud vendors usually charge extra for logs; in extreme cases, the cost of the logs can be higher than the RDS itself.</li>
    </ul>
    </div>
        )
    }

    const getRemediation = () => {
        const { X, Y, Z, A, B, C } = params as Params;
        return (
            <div>
                <ul>
                    <li><b>Analyze the histogram of query durations:</b><br/>
                    <ul><li>Analyze the histogram of query durations, from the last 7 days, to set a reasonable log_min_duration_statement.</li></ul>
                </li>
                <table>
                    <thead>
                    <tr>
                        <th>Query Duration Range (ms)</th>
                        <th>Number of Queries (last 7 days)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>0 - 100</td>
                        <td>{X}</td>
                    </tr>
                    <tr>
                        <td>101 - 200</td>
                        <td>{Y}</td>
                    </tr>
                    <tr>
                        <td>201 - 500</td>
                        <td>{Z}</td>
                    </tr>
                    <tr>
                        <td>501 - 1000</td>
                        <td>{A}</td>
                    </tr>
                    <tr>
                        <td>1001 - 2000</td>
                        <td>{B}</td>
                    </tr>
                    <tr>
                        <td>2000 +</td>
                        <td>{C}</td>
                    </tr>
                    </tbody>
                </table>

                <li><b>Adjust </b><code>log_min_duration_statement</code> Setting:<br/>
                <ul><li>Based on the evaluation, set <code>log_min_duration_statement</code> to a value that captures slow queries without logging an excessive number of normal queries.</li><br/>
                <li>Example: If most queries execute within 500ms, but you are interested in queries taking longer than 1 second, set <code>log_min_duration_statement</code> to 1000.</li></ul></li><br/>
        <li><b>Configure</b> <code>log_statement_sample_rate</code> to log only one out of every X queries, reducing the overall volume of logged queries.<br/>
        <code>log_statement_sample_rate = 0.1 # logs approximately 10% of all statements</code></li><br/>
        <li><b>Utilize pg_badger for Log Analysis:</b> Use <a href="https://github.com/darold/pgbadger">pg_badger</a> to parse PostgreSQL logs and generate performance reports. parse PostgreSQL logs to generate comprehensive performance reports; adjust or disable logs if they fail to provide meaningful insights.</li><br/>
                    <li><b>Automate Alerts for Disk Usage:</b> Configure a new Metis <a href="https://demo.app.metisdata.io/rules">Alert</a> on the "Free Storage Space" metric.</li>
                </ul>
        </div>
        )
    }
    return (
        <InsightsAccordion title={'log_min_duration_statement'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                        <IconWrapper>
                            <InsightsDetailsIcon width={24} />
                        </IconWrapper>
                        <FlexFullWrapper direction="column" align="start">
                            <Label weight={600} h4>
                                Insight Details
                            </Label>
                            <DetailsWrapper>
                                <Details>{getDetails()}</Details>
                            </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default LogMinDurationStatementInsight;