import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

type Params = {
    X: number;
    Y: number;
    Z: number;
    A: number;
    B: number;
    C: number;
};

const AutoExplainLogAnalyzeInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>
                The <code>auto_explain.log_analyze</code> parameter, part of the <code>auto_explain</code> module, logs execution plans of slow queries automatically. When enabled, it records actual runtime statistics for each node in the query execution plan, including rows processed and processing time.<br/>
                The <code>auto_explain.log_analyze</code> parameter, part of the <code>auto_explain</code> module, logs execution plans of slow queries automatically. When set to true, PostgreSQL logs the actual execution plan with runtime statistics for each node, similar to running <code>EXPLAIN ANALYZE</code>. This includes rows processed and processing time, providing deeper insights into query performance and helping administrators and developers identify and address performance bottlenecks more effectively.<br/><br/>
                <b>Related Configurations</b><br/><br/>
                Several other configurations work in conjunction with <code>auto_explain.log_analyze</code> to control the behavior and output of the <code>auto_explain</code> module:<br/>
                <ul><li><code>auto_explain.log_format</code>: Defines the format of the logged plan (e.g., text, json, xml, yaml).</li><br/>
                    <li><code>auto_explain.log_min_duration</code>: Sets the minimum execution time (in milliseconds) above which the execution plan of a query will be logged. This helps in logging only the slow queries.</li><br/>
                    <li><code>auto_explain.log_buffers</code>: When enabled, logs the number of shared buffers hit, read, dirtied, and written by the query.</li><br/>
                    <li><code>auto_explain.log_timing</code>: If enabled, logs the actual time spent in each node of the query plan.</li><br/></ul>
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                1. <b>Performance Overhead:</b> Setting <code>auto_explain.log_min_duration</code> too low (e.g., 0 to log all queries) can introduce significant performance overhead, especially in a high-transaction environment. This can lead to increased CPU and I/O usage, slowing down the database.<br/>
                2. <b>Log Bloat:</b> Logging too many queries can result in log bloat, making it difficult to sift through logs to find relevant information. This can also consume significant disk space.<br/>
                3. <b>Missing Critical Information:</b> Setting <code>auto_explain.log_min_duration</code> too high might result in missing the execution plans of moderately slow queries that could benefit from optimization.<br/>
                4. <b>Incomplete Analysis:</b> Disabling <code>auto_explain.log_analyze</code>, <code>auto_explain.log_buffers</code>, or <code>auto_explain.log_timing</code> can lead to incomplete analysis, as you may miss critical details required to diagnose performance issues.<br/><br/>
                <b>Impact of a Wrong Configuration</b><br/><br/>
                Improper configuration of <code>auto_explain.log_analyze</code> and related settings can have several adverse effects:<br/>
                <ul><li><b>Performance Overhead:</b> Enabling detailed logging (especially with <code>log_analyze</code>, <code>log_timing</code>, and <code>log_buffers</code>) for all queries or setting a very low threshold for <code>log_min_duration</code> can significantly increase the I/O load on the server, impacting overall database performance.</li><br/>
                    <li><b>Log Bloat:</b> Logging too many queries can result in log bloat, making it difficult to sift through logs to find relevant information. This can also consume significant disk space.</li></ul>
            </div>
        )
    }

    const getRemediation = () => {
        const { X, Y, Z, A, B, C } = params as Params;
        return (
            <div>
                <ul>
                    <li><b>Analyze the histogram of query durations:</b><br/>
                        <ul><li>Analyze the histogram of query durations, from the last 7 days, to set a reasonable <code>auto_explain.log_min_duration</code>.</li></ul>
                    </li>
                    <table>
                        <thead>
                        <tr>
                            <th>Query Duration Range (ms)</th>
                            <th>Number of Queries (last 7 days)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>0 - 100</td>
                            <td>{X}</td>
                        </tr>
                        <tr>
                            <td>101 - 200</td>
                            <td>{Y}</td>
                        </tr>
                        <tr>
                            <td>201 - 500</td>
                            <td>{Z}</td>
                        </tr>
                        <tr>
                            <td>501 - 1000</td>
                            <td>{A}</td>
                        </tr>
                        <tr>
                            <td>1001 - 2000</td>
                            <td>{B}</td>
                        </tr>
                        <tr>
                            <td>2000 +</td>
                            <td>{C}</td>
                        </tr>
                        </tbody>
                    </table>

                    <li><b>Adjust </b><code>auto_explain.log_min_duration</code> Setting:<br/>
                        <ul><li>Based on the evaluation, set <code>auto_explain.log_min_duration</code> to a value that captures slow queries without logging an excessive number of normal queries.</li><br/>
                            <li>Example: If most queries execute within 500ms, but you are interested in queries taking longer than 1 second, set <code>auto_explain.log_min_duration</code> to 1000.</li></ul></li><br/>
                    <li><b>Configure</b> <code>auto_explain.sample_rate</code> to log only one out of every X queries, reducing the overall volume of logged queries.<br/>
                        <code>ALTER SYSTEM SET auto_explain.sample_rate = 0.1 # generate plans for approximately 10% of all statements</code></li><br/>
                    <li><b>Analyze the plan:</b> Analyzing the execution plan may be complex, requiring prior knowledge of database internals. Use <a href="https://demo.app.metisdata.io/query-analysis">Metis Query Analyzer</a> to simplify visualization and gain insights into the plan.</li><br/>
                    <li><b>Configure the format </b> of the plan to JSON. Metis query analyzer only supports JSON. The JSON plan is also more detailed than the plain text format.</li><br/>
                    <li><code>ALTER SYSTEM SET auto_explain.log_format = json;</code></li><br/>
                    <li><b>Automate Alerts for Disk Usage:</b> Configure a new Metis <a href="https://demo.app.metisdata.io/rules">Alert</a> on the "Free Storage Space" metric.</li><br/>
                    <li><b>Disable</b> <code>auto_explain.log_analyze</code>: To avoid enabling <code>auto_explain.log_analyze</code> unnecessarily, as it generates larger execution plans and potentially impacts performance, reserve its use for addressing complex performance issues that necessitate detailed analysis of actual execution plans.</li></ul><br/>
                <code>ALTER SYSTEM SET auto_explain.log_analyze = false;</code>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'auto_explain.log_analyze'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default AutoExplainLogAnalyzeInsight;