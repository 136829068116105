import { useCallback } from 'react';
import { Tooltip, XAxis, YAxis, Area, ComposedChart, CartesianGrid, ResponsiveContainer, Bar } from 'recharts';
import { useContext, useEffect, useState } from 'react';
import {Grid, IconButton, Skeleton, Tab, Tabs} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as NoRecentActivityData } from '@icons/no-data-found-traces.svg';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import Typography from '../../../core/Typography';
import { GridContainer } from '../../LogAnalysis/LogAnalyzer.styled';
import { analyticsTrack } from '../../../utils/segment-analytics';
import {
  extractApiKeyByHost,
  formatSQL,
  getDuration,
  getFactsAsKeyVal,
  numberWithCommas,
  toNumbersSuffix
} from '../../../utils/utils';
import MDrawer from '../../../core/Drawer/Drawer';
import {
  Arrow,
  ArrowWrapper,
  BodyDrawer,
  Close,
  Container,
  ContainerItem,
  DrawerItemWrapper,
  GridItem,
  HeaderDrawer,
  PlanWrapper,
  SortI,
  SortIconWrapper,
  TabWrapper,
  TextDrawer,
  TitleDrawer,
  TitleTooltip,
  TitleWrapper,
  TooltipContainer,
  WrapperList
} from '../Reports.styled';
import NoData from '../../../components/NoData/NoData';
import TableLoader from '../../../components/Loader/TableLoader';
import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import Flex from '../../../core/Flex';
import { ApiKeyType, useActiveAPIKey } from '../../../atoms/api-keys.atom';
import { queryStatisticDataItemT, QueryStats, ReportAtomState } from './types';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import { debugModeState } from '../../../atoms/debug-mode.atom';
import Button from '../../../core/Button/Button';
import { QueryTabState } from '../utils';
import { EmptyReport, getInsightsByIdAPI, getStatisticQueryAPI, getStatisticQueryByIdAPI } from '../utils/ReportAPI';
import { AssertionTab } from '../../../components/AssertionsTab/AssertionTab';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import BasicSpinnerLoader from '../../../components/Loader/BasicSpinnerLoader';
import { QUERY_PER_PAGE, sortQuery } from '../consts';
import SeveritiesSummary from '../../../components/SeveritiesSummary/SeveritiesSummary';
import { TabPanel } from '../../../core/Tabs/Tabs';
import { LinkHref } from '../../PerformanceDetails/PerformanceDetails.styled';
import { PGPlanViewer } from '../../../components/PGPlanViewer/PGPlanViewer';
import Search from '../../../core/Search';
import useDebounce from '../../../hooks/useDebounce';
import { transformData } from '../../../components/Table/TableItem';
import { DateDrawer, TimeFrameContainer, TooltipItem } from '../../ObservabilityDashboard/components/HChart/HChart';
import { RDBMS } from '../../../utils/constants';
import { userSettingsAtom } from '../../../atoms/user.settings.atom';
import { AI_Advice } from '../common/AIAdvice';
import {RefreshIcon} from "../../Alerts/Alerts.styled";
import {
  ReactFlow,
  Background,
  Controls,
  MiniMap,
  addEdge,
  useNodesState,
  useEdgesState,
  type OnConnect
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import { nodeTypes } from '../components/nodes'
import { parseExplainData, SQLExplainEdge, SQLExplainNode } from '../utils'
import {SideBarRF} from "../../../components/SideBarRF/SideBarRF";

export const QueryStatistics = () => {
  const type: ReportAtomState = 'dataQueryStats';
  const restClient = useContext(RestClientContext);
  const { hostId = '', databaseId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const flagEnabled = !useFeatureFlagEnabled('TopQueryAIAdvice');
  const queryId = searchParams.get('queryId');
  const [selectedQuery, setSelectedQuery] = useState<QueryStats>();
  const [loading, setLoading] = useState<boolean>(false);
  const [queryStatisticDataItem, setQueryStatisticDataItem] = useState<queryStatisticDataItemT[]>();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [tabState, setTabState] = useState(0);
  const [isGetOffset, setOffset] = useState(false);
  const [debugDrawerState, setDebugDrawerState] = useState(false);
  const [drawerLoadingState, setDrawerLoadingState] = useState<boolean>(false);
  const [{ [type]: reportsState, selectedType, databaseId: selectedDatabaseId, rdbms }, setReportsState] =
    useRecoilState(reportsStateAtom);
  const isDebugMode = useRecoilValue(debugModeState);
  const [queryTrace, setQueryTrace] = useState<any>();
  const [assertionIndex, setAssertionIndex] = useState(0);
  const [isInsightLoading, setInsightLoading] = useState(false);
  const settings = useRecoilValue(userSettingsAtom);
  const selectedApiKey: ApiKeyType = useActiveAPIKey(extractApiKeyByHost(hostId, settings.hostsApiKey));
  const [sortBy, setSortBy] = useState(reportsState.sortBy);
  const [sortOrder, setSortOrder] = useState(reportsState.sortOrder);
  const [sqlSearch, setSqlSearch] = useState(reportsState.sql);
  const [selectedNode, setSelectedNode] = useState(null);
  const isMySQL: boolean = false; // FF is off
  let mysqlPlan: boolean| null = rdbms === RDBMS.mysql;
  const debouncedSearchSQL = useDebounce(sqlSearch || '', 1000);
  const sortedOffset =
    reportsState.sql !== debouncedSearchSQL || reportsState.sortBy !== sortBy || reportsState.sortOrder !== sortOrder
      ? 0
      : reportsState.offset;
  const handleTabChange = (event: any, newValue: number) => {
    event.preventDefault();
    analyticsTrack('QueryStats Tab ', { tab: QueryTabState[newValue] });
    setTabState(newValue);
  };

  const getQueryStatisticById = async (item: QueryStats) => {
    setDrawerLoadingState(true);
    setDrawerState(true);

    if (item?.query_id && item.query_id !== selectedQuery?.query_id && item.query_id) {
      setTabState(queryId ? 1 : 0);
      setSelectedQuery(item);
      const queryById: queryStatisticDataItemT[] = await getStatisticQueryByIdAPI(
        restClient,
        extractApiKeyByHost(hostId, settings.hostsApiKey),
        item.query_id,
        databaseId
      );
      setQueryStatisticDataItem(queryById);
    }
    setDrawerLoadingState(false);
    setSearchParams();
  };
  const getInsightsById = async () => {
    setInsightLoading(true);
    setAssertionIndex(0);
    const resQueries = await getInsightsByIdAPI(
      restClient,
      extractApiKeyByHost(hostId, settings.hostsApiKey),
      selectedQuery?.trace_id || '',
      databaseId,
      selectedQuery?.query_id || ''
    );
    if (resQueries.length) {
      setQueryTrace({ ...resQueries[0], trace_id: selectedQuery?.trace_id });
    }
    setInsightLoading(false);
  };

  const getStatisticQuery = async (isRefresh?: boolean) => {
    setLoading(true);
    const { rows = [], rdbms = '' }: { rows: QueryStats[]; rdbms?: RDBMS | '' } = await getStatisticQueryAPI(
      restClient,
      extractApiKeyByHost(hostId, settings.hostsApiKey),
      databaseId,
      sortedOffset,
      debouncedSearchSQL,
      sortBy,
      sortOrder
    );
    if (reportsState.sql !== debouncedSearchSQL || reportsState.sortBy !== sortBy || reportsState.sortOrder !== sortOrder || isRefresh) {
      setReportsState((old: reportsStateI) => ({
        ...old,
        selectedType: type,
        databaseId: databaseId,
        rdbms,
        [type]: {
          rows,
          lastUpdateIsoDate: old[type].lastUpdateIsoDate,
          offset: QUERY_PER_PAGE,
          sortBy,
          sortOrder,
          sql: debouncedSearchSQL
        }
      }));
    } else {
      setReportsState((old: reportsStateI) => ({
        ...old,
        selectedType: type,
        databaseId: databaseId,
        rdbms,
        [type]: {
          rows: [...reportsState.rows, ...rows],
          lastUpdateIsoDate: old[type].lastUpdateIsoDate,
          offset: reportsState.offset + QUERY_PER_PAGE,
          sortBy,
          sortOrder,
          sql: debouncedSearchSQL
        }
      }));
    }
    setOffset(false);
    setLoading(false);
  };

  const onNodeClick = (event, node) => {
    setSelectedNode(node);
  };

  const [nodes, setNodes, onNodesChange] = useNodesState<SQLExplainNode>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<SQLExplainEdge>([]);

  useEffect(() => {
    if (queryTrace?.plan) {
      const { nodes: parsedNodes, edges: parsedEdges } = parseExplainData(
          queryTrace.plan.Plan,
          'MySQL',
          formatSQL(selectedQuery?.query.replace(/`/g,'') || '')
      );
      setNodes(parsedNodes);
      setEdges(parsedEdges);
    }
  }, [queryTrace, setNodes, setEdges]);


  const onConnect: OnConnect = useCallback(
      (connection) => setEdges((eds) => addEdge(connection, eds)),
      [setEdges]
  );
  // TODO: remove feature flags...
  if(isMySQL) mysqlPlan = null;
  const CustomTooltip = (data: any) => {
    const { active, payload } = data;
    if (active && payload && payload.length) {
      const data = payload[0]?.payload;
      const timeFrame = getDuration(queryStatisticDataItem?.[0]?.created_at || '', queryStatisticDataItem?.[1]?.created_at || '');

      return (
        <TooltipContainer>
          <DateDrawer>
            <TimeFrameContainer>
              <TitleTooltip>{timeFrame}</TitleTooltip>
            </TimeFrameContainer>
            <TitleTooltip>{dayjs(data?.created_at).format('MMM-DD HH:00')}</TitleTooltip>
          </DateDrawer>
          <TooltipItem direction="column" align="start">
            <Flex justify="start">
              <TitleTooltip weight="600">Hourly Avg Duration (ms):</TitleTooltip>
              <TextDrawer>{numberWithCommas(data?.mean_exec_time, 3)}</TextDrawer>
            </Flex>

            <Flex justify="start">
              <TitleTooltip weight="600">Hourly Calls:</TitleTooltip>
              <TextDrawer>{numberWithCommas(Math.abs(data?.calls_delta))}</TextDrawer>
            </Flex>
          </TooltipItem>
        </TooltipContainer>
      );
    }
    return null;
  };

  useEffect(() => {
    if (selectedType !== type || (selectedDatabaseId && selectedDatabaseId !== databaseId)) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        databaseId,
        [type]: selectedDatabaseId !== databaseId ? EmptyReport : reportsState
      }));
    }
    if (
      hostId &&
      databaseId &&
      (reportsState.rows.length === reportsState.offset ||
        reportsState.sortBy !== sortBy ||
        reportsState.sortOrder !== sortOrder ||
        reportsState.sql !== debouncedSearchSQL) &&
      !loading
    ) {
      getStatisticQuery();
    }
  }, [hostId, databaseId, reportsState.offset, reportsState.rows.length, isGetOffset, debouncedSearchSQL, sortOrder, sortBy]);

  useEffect(() => {
    setQueryTrace(undefined);
    if (selectedQuery?.trace_id && queryTrace?.trace_id !== selectedQuery?.trace_id) {
      getInsightsById();
    } else if (selectedQuery?.trace_id === null) {
      setQueryTrace(undefined);
    }
  }, [selectedQuery?.query_id, databaseId]);

  useEffect(() => {
    if (queryId) {
      const selected = reportsState.rows.find((r) => r.query_id === queryId);
      selected && getQueryStatisticById(selected);
    }
  }, [reportsState.rows]);

  return (
    <>
      <Container>
        {loading && !reportsState.rows.length ? (
          <TableLoader size={10} />
        ) : (
          <>
            <Container direction="column">
              <GridContainer container>
                <Grid
                  item
                  xs={6.8}
                  sx={{
                    padding: '2px 8px'
                  }}
                >
                  <GridItem justify="space-between">
                    <TitleWrapper weight="600">SQL Command</TitleWrapper>
                    <Flex margin="0 0 0 12px">
                      <Search
                        fullWidth
                        placeholder="Search SQL"
                        value={sqlSearch}
                        onSubmit={setSqlSearch}
                        onChangeCallback={setSqlSearch}
                      />
                      <IconButton
                          onClick={() => {
                            getStatisticQuery(true);
                          }}
                          size="small"
                          id="basic-button"
                          aria-haspopup="true"
                      >
                        <RefreshIcon title="refresh-icon"/>
                      </IconButton>
                    </Flex>
                  </GridItem>
                </Grid>
                <Grid item xs={1.7}>
                  <GridItem
                    isClickable={!loading}
                    onClick={() => {
                      if (!loading) {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                        setSortBy(sortQuery.calls);
                      }
                    }}
                  >
                    <TitleWrapper weight="600">Calls</TitleWrapper>
                    <SortIconWrapper isActive={sortOrder === 'asc'}>{sortBy === sortQuery.calls && <SortI />}</SortIconWrapper>
                  </GridItem>
                </Grid>
                <Grid item xs={1.4}>
                  <GridItem
                    justify="start"
                    isClickable={!loading}
                    onClick={() => {
                      if (!loading) {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                        setSortBy(sortQuery.duration);
                      }
                    }}
                  >
                    <TitleWrapper weight="600">Avg Duration (ms)</TitleWrapper>
                    <SortIconWrapper isActive={sortOrder === 'asc'}>{sortBy === sortQuery.duration && <SortI />}</SortIconWrapper>
                  </GridItem>
                </Grid>
                <Grid item xs={1} sx={{ pl: 2 }}>
                  <GridItem
                    justify="start"
                    isClickable={!loading}
                    onClick={() => {
                      if (!loading) {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                        setSortBy(sortQuery.insights);
                      }
                    }}
                  >
                    <TitleWrapper weight="600">Insights</TitleWrapper>
                    <SortIconWrapper isActive={sortOrder === 'asc'}>{sortBy === sortQuery.insights && <SortI />}</SortIconWrapper>
                  </GridItem>
                </Grid>
              </GridContainer>
              <WrapperList>
                {reportsState.rows.length && sortedOffset !== 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    initialLoad={false}
                    loadMore={() => {
                      setOffset(true);
                    }}
                    hasMore={reportsState.rows.length % QUERY_PER_PAGE === 0 && !loading}
                  >
                    {reportsState.rows.map((item: QueryStats, i: number) => (
                      <ContainerItem
                        isActive={item?.query_id === selectedQuery?.query_id && !selectedApiKey?.isDemo}
                        key={i}
                        onClick={() => getQueryStatisticById(item)}
                      >
                        <Grid
                          container
                          sx={{
                            padding: '12px 22px'
                          }}
                        >
                          <Grid item xs={7.2}>
                            <GridItem justify="start">
                              <Typography>
                                {item?.query && item?.query?.length > 200
                                 ? item?.query.replace(/`/g,'').slice(0, 200) + '...' 
                                 : item?.query.replace(/`/g,'')}
                              </Typography>
                            </GridItem>
                          </Grid>
                          <Grid item xs={1}>
                            <GridItem>
                              <Typography>
                                {toNumbersSuffix(item?.calls).num + '' + toNumbersSuffix(item?.calls).suffix}
                              </Typography>
                            </GridItem>
                          </Grid>
                          <Grid item xs={1.4}>
                            <GridItem justify="center">
                              <Typography> {numberWithCommas(item?.mean_exec_time, 3)}</Typography>
                              <ArrowWrapper className="arrow-icon-item">
                                <Arrow title="arrow-next" />
                              </ArrowWrapper>
                            </GridItem>
                          </Grid>
                          <Grid item xs={2.4}>
                            <GridItem justify="end">
                              <SeveritiesSummary
                                severity_critical={item?.severities?.severity_critical}  
                                severity_high={item?.severities?.severity_high}
                                severity_medium={item?.severities?.severity_medium}
                                severity_low={item?.severities?.severity_low}
                              />
                            </GridItem>
                          </Grid>
                        </Grid>
                      </ContainerItem>
                    ))}
                  </InfiniteScroll>
                ) : (
                  sortedOffset !== 0 && <NoData title={'No data was found.'} subTitle="" icon={<NoRecentActivityData />} />
                )}
                {loading && (
                  <Flex>
                    <BasicSpinnerLoader />
                  </Flex>
                )}
              </WrapperList>
            </Container>
            <MDrawer onClose={() => setDebugDrawerState(false)} anchor="right" open={debugDrawerState}>
              <Flex direction="column" align="end">
                <Flex margin="8px 16px">
                  <Close title="close-icon" onClick={() => setDebugDrawerState(false)} />
                </Flex>
                <CodeEditor
                  isCopyValue
                  readonly={true}
                  width="900px"
                  height="90vh"
                  value={formatSQL(JSON.stringify(queryStatisticDataItem, undefined, 2))}
                />
              </Flex>
            </MDrawer>
            <MDrawer
              onClose={() => setDrawerState(false)}
              anchor="right"
              open={drawerState}
              variant={drawerState ? 'persistent' : undefined}
            >
              <DrawerItemWrapper justify="start" align="start" direction="column">
                <HeaderDrawer justify="space-between">
                  <TitleDrawer h3 weight="600">
                    SQL Command Details
                  </TitleDrawer>
                  <Flex margin="8px 16px">
                    {isDebugMode && (
                      <Button variant="link" onClick={() => setDebugDrawerState(true)}>
                        <Typography h4 weight={600}>
                          Raw Info
                        </Typography>
                      </Button>
                    )}
                    <Close title="close-icon" onClick={() => setDrawerState(false)} />
                  </Flex>
                </HeaderDrawer>
                <BodyDrawer width="914px" justify="start" align="start" direction="column">
                  <Tabs sx={{ width: '100%', mt: 1 }} value={tabState} onChange={handleTabChange}>
                    {/*TODO: remove filter and all indexes of isMySQL when its not feature flag anymore*/}
                    {QueryTabState.filter((t) => (isMySQL ? t === 'SQL' || t === 'Insights' || t === 'Plan JSON' || t === 'AI Advice' : true)).map(
                      (tabItem: string) =>
                        tabItem === 'AI Advice' ? (
                          <Tab
                            key={tabItem}
                            sx={{ textTransform: 'none', fontWeight: 600, color: '#A80221FF' }}
                            id={`query-tab-${tabItem}`}
                            label={tabItem}
                          />
                        ) : (
                          <Tab
                            key={tabItem}
                            sx={{ textTransform: 'none', fontWeight: 600 }}
                            id={`query-tab-${tabItem}`}
                            label={tabItem}
                          />
                        )
                    )}
                  </Tabs>
                  <TabPanel value={tabState} index={0}>
                    <TabWrapper>
                      <Flex margin="12px 24px" direction="column" align="start">
                        {drawerLoadingState ? (
                          <Skeleton variant="rectangular" animation="wave" width="100%" height="28vh" sx={{ pt: '2px' }} />
                        ) : (
                          <CodeEditor
                            isCopyValue
                            language="sql"
                            readonly={true}
                            height="28vh"
                            width="100%"
                            value={formatSQL(selectedQuery?.query.replace(/`/g,'') || '')}
                          />
                        )}
                        <Flex margin="8px 0">
                          <TitleDrawer weight="600">Average Duration (ms)</TitleDrawer>
                        </Flex>

                        <ResponsiveContainer width="100%" height={200}>
                          {drawerLoadingState ? (
                            <Skeleton variant="rectangular" animation="wave" width="100%" height={80} />
                          ) : (
                            <ComposedChart data={queryStatisticDataItem} syncId="queryStatisticId">
                              <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                              <XAxis
                                dataKey="created_at"
                                tickMargin={10}
                                minTickGap={20}
                                tickFormatter={(x) => dayjs(x).format('MMM-DD HH:00')}
                                interval="preserveEnd"
                                tick={{ fill: '#B0B0CC', fontSize: 12 }}
                              />
                              <Tooltip content={CustomTooltip} wrapperStyle={{ border: 'none' }} />
                              <Bar type="monotone" dataKey="mean_exec_time" fill="#608EDC" fillOpacity={1} maxBarSize={10} />

                              <YAxis tick={{ fontSize: 12 }} />
                            </ComposedChart>
                          )}
                        </ResponsiveContainer>
                        <Flex margin="8px 0">
                          <TitleDrawer weight="600">Hourly Calls</TitleDrawer>
                        </Flex>
                        <ResponsiveContainer width="100%" height={200}>
                          {drawerLoadingState ? (
                            <Skeleton variant="rectangular" animation="wave" width="100%" height={80} />
                          ) : (
                            <ComposedChart data={queryStatisticDataItem}>
                              <CartesianGrid stroke="#f5f5f5" />
                              <XAxis
                                dataKey="created_at"
                                tickMargin={10}
                                minTickGap={20}
                                tickFormatter={(x) => dayjs(x).format('MMM-DD HH:00')}
                                interval="preserveEnd"
                                tick={{ fill: '#B0B0CC', fontSize: 12 }}
                              />
                              <Tooltip content={CustomTooltip} wrapperStyle={{ border: 'none' }} />
                              <Bar dataKey="calls_deltaRed" stroke="none" fill="#608EDC" maxBarSize={10} />

                              <YAxis tick={{ fontSize: 12 }} />
                            </ComposedChart>
                          )}
                        </ResponsiveContainer>
                      </Flex>
                    </TabWrapper>
                  </TabPanel>
                  <TabPanel value={tabState} index={1}>
                    <TabWrapper>
                      {queryTrace ? (
                        <AssertionTab
                          assertions={queryTrace?.assertions?.success || []}
                          headHeight="120px"
                          facts={getFactsAsKeyVal(queryTrace?.facts || {})}
                          assertionCurrentIndex={assertionIndex}
                          setAssertionIndexCallback={setAssertionIndex}
                          tableInfos={transformData(queryTrace?.tableInfos || [])}
                          changeTabActivity={0}
                          isLoading={isInsightLoading}
                          selectedQuery={0}
                          tableItem={queryTrace}
                          isSidebar={true}
                        />
                      ) : (
                        <NoData
                          title={() => (
                            <Typography h3 weight="600">
                              Insights unavailable...
                            </Typography>
                          )}
                          subTitle={`The system could not find an execution plan for the query ID ${selectedQuery?.query_id}`}
                          icon={<NoRecentActivityData />}
                        />
                      )}
                    </TabWrapper>
                  </TabPanel>
                  <TabPanel value={tabState} index={isMySQL ? 2 : 2}>
                    <TabWrapper>
                      {flagEnabled ? (
                        <AI_Advice
                          query={selectedQuery}
                          apiKey={extractApiKeyByHost(hostId, settings.hostsApiKey)}
                          restClient={restClient}
                          isQueryPlan={!!queryTrace?.plan}
                          originalCost={isMySQL
                              ? queryTrace?.plan?.Plan?.cost_info?.query_cost
                              : queryTrace?.plan?.Plan['Total Cost']}
                        />
                      ) : (
                        <NoData
                          title=""
                          subTitle={'This feature is currently under development. Please check back later for updates.'}
                          icon={<NoRecentActivityData />}
                        />
                      )}
                    </TabWrapper>
                  </TabPanel>
                  <TabPanel value={tabState} index={isMySQL ? 5 : 3}>
                    <TabWrapper>
                      <PlanWrapper>
                        {queryTrace?.query ? (
                            mysqlPlan ? (
                                <>
                                  <ReactFlow
                                      proOptions={{ hideAttribution: true }}
                                      nodes={nodes}
                                      nodeTypes={nodeTypes}
                                      onNodesChange={onNodesChange}
                                      edges={edges}
                                      onEdgesChange={onEdgesChange}
                                      onConnect={onConnect}
                                      onNodeClick={onNodeClick}
                                      // fitView
                                      defaultViewport={{
                                        x: 1000,
                                        y: 350,
                                        zoom: 0.7
                                      }}
                                      fitViewOptions={{
                                        padding: 20,
                                        minZoom: 0.5,
                                        maxZoom: 1.5,
                                      }}
                                  >
                                    <Background />
                                    <MiniMap />
                                    <Controls />
                                  </ReactFlow>
                                  {selectedNode && (
                                      <SideBarRF node={selectedNode} onClose={() => setSelectedNode(null)} />
                                  )}
                                </>
                            ) : (
                                <>
                                  <Flex justify={'flex-start'}>
                                    <Typography style={{ paddingRight: 3 }} weight={600}>
                                      Powered by
                                    </Typography>
                                    <LinkHref href="https://explain.dalibo.com/" target="_blank">
                                      explain.dalibo.com
                                    </LinkHref>
                                  </Flex>
                                  <PGPlanViewer
                                      query={queryTrace?.query || ''}
                                      plan={JSON.stringify(queryTrace?.plan, null, 2)}
                                  />
                                </>
                            )
                        ) : (
                            <NoData
                                title={() => (
                                    <Typography h3 weight="600">
                                      Execution Plan unavailable...
                                    </Typography>
                                )}
                                subTitle={`The system could not find an execution plan for the query ID ${selectedQuery?.query_id}`}
                                icon={<NoRecentActivityData />}
                            />
                        )}
                      </PlanWrapper>
                    </TabWrapper>
                  </TabPanel>
                  <TabPanel value={tabState} index={isMySQL ? 3 : 4}>
                    <TabWrapper>
                      {queryTrace?.query ? (
                        <CodeEditor
                          isCopyValue
                          readonly={true}
                          height={'88vh'}
                          value={JSON.stringify(
                            typeof queryTrace?.plan === 'string' ? JSON.parse(queryTrace?.plan) : queryTrace?.plan,
                            null,
                            2
                          )}
                        />
                      ) : (
                        <NoData
                          title={() => (
                            <Typography h3 weight="600">
                              Plan JSON unavailable...
                            </Typography>
                          )}
                          subTitle={`The system could not find an execution plan for the query ID ${selectedQuery?.query_id}`}
                          icon={<NoRecentActivityData />}
                        />
                      )}
                    </TabWrapper>
                  </TabPanel>
                </BodyDrawer>
              </DrawerItemWrapper>
            </MDrawer>
          </>
        )}
      </Container>
    </>
  );
};
