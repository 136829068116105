import styled from "styled-components";
import Flex from "../../core/Flex";

export const Container = styled(Flex)`
  &:first-child {
    margin-top: 0px;
    display: block;
  }
  &:last-child {
    margin-bottom: 5px;
  }
  margin-top: 4px;
  width: calc(100% - 56px);
  margin-left: 16px;
  border-bottom: ${({ theme, hideLine }) => !hideLine && `1px solid ${theme.colors.grey[150]}`};
`;

export const FlexFullWrapper = styled(Flex)`
  width: 100%;
`;

export const IconWrapper = styled(Flex)`
  padding: 5px;
  margin-right: 6px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.new.primary[150]};
`;


