import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { Tab, Tabs } from '@mui/material';

import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import {
  BodyDrawer,
  CellTitle,
  Container,
  DataGridUI,
  DataGridUIContainer,
  HistoryContainer,
  HistoryIcon,
  SettingsIcon, TabContainer
} from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { ReportAtomState } from './types';
import Typography from '../../../core/Typography';
import MTooltip from '../../../core/Tooltip/Tooltip';
import MDrawer from '../../../core/Drawer/Drawer';
import {getConfigurationsInsightsAPI, getDbConfigHistoryAPI, getDbConfigReportAPI} from '../utils/ReportAPI';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { SeverityLabel } from '../../../components/SeverityLabel/SeverityLabel';
import Search from '../../../core/Search';
import Flex from '../../../core/Flex';
import { userSettingsAtom } from '../../../atoms/user.settings.atom';
import {extractApiKeyByHost} from '../../../utils/utils';
import { TabPanel } from '../../../core/Tabs/Tabs';
import { RDBMS } from '../../../utils/constants';
import HistoryCard from '../common/HistoryCard';
import {
  ConfigurationInsightsProps, ConfigurationInsightTypes,
  ConfigurationPropertyToInsightMap
} from "../../../components/InsightsAccordion/ConfigurationsInsights/ConfigurationInsightsMapper";
import ConfigurationInsightsMapper
  from "../../../components/InsightsAccordion/ConfigurationsInsights/ConfigurationInsightsMapper";

const getMostCriticalInsightByConfigurationProperty = (configuration: string, insights: ConfigurationInsightsProps[]) => {
  const relevantInsightsKeys = ConfigurationPropertyToInsightMap[configuration];
  const relevantInsights: ConfigurationInsightsProps[] = insights?.filter(insight => relevantInsightsKeys?.includes(insight.type as ConfigurationInsightTypes))
  const mostCriticalInsight = relevantInsights && relevantInsights.length ? Math.min(...relevantInsights?.map(insight => insight.severity)) : null;

  return mostCriticalInsight;
}

const insightsFormatter = (params: ValueFormatterParams, insights: ConfigurationInsightsProps[]) => {
  const mostCriticalInsight = getMostCriticalInsightByConfigurationProperty(params?.data?.name, insights);
  return (
      mostCriticalInsight ? <SeverityLabel priority={mostCriticalInsight} /> : null
  );
};

export const DbConfigReport = () => {
  const type: ReportAtomState = 'dataDBConfig';
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [rowData, setRowData]: any[] = useState([]);
  const [insights, setInsights] = useState<ConfigurationInsightsProps[]>(null);
  const restClient = useContext(RestClientContext);
  const [confSearch, setConfigSearch] = useState('');
  const { state }: any = useLocation();

  const [isLoading, setLoading] = useState(false);
  const { hostId = '' } = useParams();
  const [{ [type]: reportsState, selectedType }, setReportsState] = useRecoilState(reportsStateAtom);
  const settings = useRecoilValue(userSettingsAtom);
  const [tabState, setTabState] = useState<number>(0);
  const [history, setHistory] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  const handleTabChange = (event: any, newValue: number) => {
    setTabState(newValue);
  };

  useEffect(() => {
    if (!history) {
      getHistoryApi();
    }
  }, []);

  const getHistoryApi = async () => {
    const res = await getDbConfigHistoryAPI(restClient, extractApiKeyByHost(hostId, settings.hostsApiKey), hostId);
    setHistory(res);
  };

  useEffect(() => {
    if (insights) {
      setColumnDefs([
        {
          field: 'name',
          headerName: 'Configuration Property',
          valueGetter: (params: any) => params?.data?.name?.toLowerCase?.()
        },
        {
          field: 'setting',
          sortable: false,
          headerName: 'Setting',
          valueGetter: (params: any) => params?.data?.setting
        },
        {
          headerName: 'Description',
          flex: 2,
          hide: state?.rdbms === RDBMS.mysql,
          valueGetter: (params: any) => params?.data?.description,
          cellRenderer: ({ value }: ValueFormatterParams) => (
              <CellTitle>
                <MTooltip disable={value?.length < 90} title={<Typography>{value}</Typography>}>
                  <Typography>{value}</Typography>
                </MTooltip>
              </CellTitle>
          )
        },
        {
          headerName: 'Insights',
          sortable: true,
          sort: 'desc',
          cellRenderer: (params: any) => insightsFormatter(params, insights),
          flex: 0.5,
          cellStyle: {justifyContent: 'left', alignItems: 'center', display: 'flex'},
          valueGetter: (params: any) => {
            return params?.data?.name
          },
          comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
            const priorityA = getMostCriticalInsightByConfigurationProperty(valueA, insights);
            const priorityB = getMostCriticalInsightByConfigurationProperty(valueB, insights);

            if (priorityA === priorityB) return 0;

            if (priorityA && priorityB) {
              return priorityA > priorityB ? -1 : 1;
            }
            if (priorityA && !priorityB) {
              return 1;
            }
            if (!priorityA && priorityB) {
              return -1;
            }
          }
        }
      ] as any)
    }
  }, [insights])

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );

  const getReports = async () => {
    setLoading(true);
    const configReportRes: any | undefined = await getDbConfigReportAPI(
      restClient,
      extractApiKeyByHost(hostId, settings.hostsApiKey),
      hostId
    );
    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: configReportRes?.data.map((el: any) => {
        if(el.name === 'effective_io_concurrency') {
            let { insight, ...rest} = el
            return rest
         }
         return el
        }
        ) || []
        , lastUpdateIsoDate: configReportRes?.updated_at || '' }
    }));
    const res = await getConfigurationsInsightsAPI(restClient, extractApiKeyByHost(hostId, settings.hostsApiKey), hostId);
    setInsights(res);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        hostId
      }));
    }
    if (hostId) {
      getReports();
    }
  }, [hostId]);

  const filteredData = useMemo(
    () =>
      !confSearch
        ? reportsState.rows
        : reportsState.rows.filter(
            (index: any) =>
              index?.setting?.includes(confSearch) || index.description?.includes(confSearch) || index.name?.includes(confSearch)
          ),
    [confSearch, reportsState.rows.length, hostId]
  );

  const selectedInsights = insights
      ?.filter(insight => {
        return ConfigurationPropertyToInsightMap[rowData?.[0]?.name]
            ?.includes(insight.type as ConfigurationInsightTypes)
      });

  return (
    <>
      <Tabs value={tabState} onChange={handleTabChange} sx={{ width: 'inherit', mt: 1 }}>
        {ConfigurationsTab.map((tabItem): any => (
          <Tab
            key={tabItem.name}
            id={`report-tab-${tabItem.name}`}
            icon={tabItem.icon}
            iconPosition="start"
            label={tabItem.name}
          />
        ))}
      </Tabs>
      <TabPanel value={tabState} index={0} style={{ width: 'inherit' }}>
        <Flex justify="start" margin="8px 0">
          <Search
            fullWidth
            placeholder="Search"
            value={confSearch}
            onSubmit={setConfigSearch}
            onChangeCallback={setConfigSearch}
          />
        </Flex>
        <Container>
          <DataGridUIContainer className="ag-theme-material" height="180px">
            {isLoading ? (
              <TableLoader size={10} />
            ) : (
              <DataGridUI
                rowData={filteredData}
                animateRows={true}
                columnDefs={columnDefs}
                suppressCellFocus={true}
                multiSortKey={'ctrl'}
                defaultColDef={defaultColDef}
                onRowClicked={(row: any) => {
                  setDrawerState(row?.data?.name !== undefined);
                  setRowData([row?.data]);
                }}
              />
            )}
          </DataGridUIContainer>
        </Container>
      </TabPanel>
      <TabPanel value={tabState} index={1} style={{ width: 'inherit' }}>
        <HistoryContainer fw height="135px">
          <HistoryCard history={history} />
        </HistoryContainer>
      </TabPanel>
      <MDrawer onClose={() => setDrawerState(false)} anchor="right" open={drawerState && !!selectedInsights.length}>
        <BodyDrawer width={'50vw'}>
          <TabContainer noHeight>
            <ConfigurationInsightsMapper insights={selectedInsights}/>
            </TabContainer>
        </BodyDrawer>
      </MDrawer>
    </>
  );
};

const ConfigurationsTab = [
  { name: 'Configurations', icon: <SettingsIcon /> },
  { name: 'Change History', icon: <HistoryIcon /> }
];
