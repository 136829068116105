import React, {FC} from 'react';
import { SidebarContainer, Header, NodeDetailsTable, TableHeader } from './SideBarRF.styled';
import {Close, Contract, TitleDrawer} from '../../routes/Reports/Reports.styled';
import Typography from "../../core/Typography";
import TableItem from "../Table/TableItem";
import { flattenObject } from "../../routes/Reports/utils/common";

export const SideBarRF: FC<SideBarRFProps> = ({ node, onClose }) => {
    const data = flattenObject(node?.data?.tableObj || node?.data || {})

    return (
        <SidebarContainer>
            <Header>
                <Contract style={{paddingLeft: 0}} onClick={onClose} />
                <TitleDrawer h3 weight="600" style={{paddingLeft: '3px'}}>
                    Node Details
                </TitleDrawer>

            </Header>
            <TableHeader>
                <Typography h4>{node?.data?.label}</Typography>
            </TableHeader>
            <NodeDetailsTable>
                <TableItem
                    data={Object.entries(data).map(([key, value]) => ({
                        ['key']: key,
                        ['Value']: value
                    })) || []}
                    emptyErrorMessage="No Data was found"
                    headHeight="120px"
                />
            </NodeDetailsTable>
        </SidebarContainer>
    );
};


interface SideBarRFProps {
    node: {
        data: {
            label?: string;
            tableObj?: Record<string, any>;
        };
    };
    onClose: () => void;
}
