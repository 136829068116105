import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const MaxWorkerProcessesInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div><b>max_worker_processes</b> in Postgres DB configuration determines the maximum number of background worker processes that can be active simultaneously. These background processes handle tasks such as autovacuum, replication, and background jobs.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div><b>max_worker_processes</b> configuration can impact the system's ability to handle concurrent tasks.
                If set too low, it may limit the number of background processes, potentially causing delays in critical
                maintenance operations or replication. If set too high, it can consume excessive system resources,
                leading to performance degradation and potential instability.</div>
        )
    }

    const getRemediation = () => {
        return (
            <div>To address incorrect <b>max_worker_processes</b> values, adjust the configuration to strike a balance
                between system resources and concurrent tasks. Monitor system performance and workload closely. Consider
                the requirements of background processes such as autovacuum and replication. Consult documentation and
                performance benchmarks to determine the optimal Max_worker_processes value for the specific database
                environment.</div>
        )
    }
    return (
        <InsightsAccordion title={'max_worker_processes'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                    <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default MaxWorkerProcessesInsight;