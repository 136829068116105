import { createDistinctNode, createGroupNode, createUnionNode, createTableNode, createSortingNode, createResultNode, createEdge, createJoinNode, createGroupByNode } from './mySQLNodeBuilder';
import { SQLOperationType, SQLExplainNode, ParseContext, ParseResult, SQLExplainEdge, NodeResult, NodeDiscoveryResult } from './dbParserTypes';
import { parseSQLToAST, extractAliasMap ,enrichNodes, replaceSQLKeywords } from './common'

export const MySQLParser = (data: any, context: ParseContext, SQLQuery: string): ParseResult => {
  const nodes: SQLExplainNode[] = [];
  const edges: SQLExplainEdge[] = [];
  const createdTableNodes = new Map<string, Set<string>>();
  let ast;
  let aliasMap: any[] | undefined;

  createdTableNodes.set('default', new Set<string>());
  const discoveryResult = discoverNodes(data, context);
  recursiveNodeBuilder(data, context, nodes, edges, null, createdTableNodes, discoveryResult);

  try {
    const query = replaceSQLKeywords(SQLQuery)
    ast = parseSQLToAST(query);
    aliasMap = extractAliasMap(ast);
  } catch (error) {
    console.error('AST Parsing Error:', error);
  }
  enrichNodes(nodes, aliasMap);

  return { nodes, edges };
};

const discoverNodes = (
    obj: any,
    context: ParseContext,
    result: NodeDiscoveryResult = { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
    level: number = 0
): NodeDiscoveryResult => {
  if (!context.discoveryTree) {
    context.discoveryTree = {};
  }

  for (const key in obj) {
    if (key === 'query_block') {
      const queryBlockKey = `query_block_${result.subqueryCount}`;
      context.discoveryTree[queryBlockKey] = {
        type: 'result',
        level: level
      };
      discoverNodes(obj[key], context, result, level + 1);
    } else if (key === 'table') {
      const tableInfo = obj[key];
      const tableNode = {
        table_name: tableInfo.table_name,
        type: 'table',
        level: level
      };
      const tableKey = `table_${tableInfo.table_name}`;
      if (!context.discoveryTree[tableKey]) {
        context.discoveryTree[tableKey] = tableNode;
      }
      if (tableInfo.materialized_from_subquery) {
        result.subqueryCount += 1;
        const subQueryKey = `subquery_${result.subqueryCount}`;
        if (!context.discoveryTree[subQueryKey]) {
          context.discoveryTree[subQueryKey] = {
            type: 'subquery',
            level: level + 1,
            children: 0,
            children_array: []
          };
        }
        const previousSubQueryKey = context.currentSubQueryKey;
        context.currentSubQueryKey = subQueryKey;
        discoverNodes(tableInfo.materialized_from_subquery.query_block, context, result, level + 1);
        context.currentSubQueryKey = previousSubQueryKey;
      }
    } else if (key === 'grouping_operation') {
      const groupingNode = {
        type: 'group_by',
        level: level
      };
      if (context.currentSubQueryKey) {
        context.discoveryTree[context.currentSubQueryKey].children_array.push(groupingNode);
        context.discoveryTree[context.currentSubQueryKey].children += 1;
      }
      discoverNodes(obj[key], context, result, level + 1);
    } else if (key === 'union_result' || key === 'union') {
      result.unionCount += 1;
      const unionKey = `union_${result.unionCount}`;
      context.discoveryTree[unionKey] = {
        type: 'union',
        level: level + 1,
        children: 0,
        children_array: []
      };

      const previousUnionKey = context.currentUnionKey;
      context.currentUnionKey = unionKey;

      if (obj[key].query_specifications) {
        for (const subQuery of obj[key].query_specifications) {
          discoverNodes(subQuery, context, result, level + 1);
        }
      }
      context.currentUnionKey = previousUnionKey;
    }

    if (typeof obj[key] === 'object' && key !== 'materialized_from_subquery' && key !== 'query_block' && key !== 'union_result') {
      discoverNodes(obj[key], context, result, level);
    }
  }
  context.hasUnion = result.unionCount > 0;
  context.hasSubQuery = result.subqueryCount > 0;

  return result;
};

const recursiveNodeBuilder = (
    obj: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    discoveryResult: NodeDiscoveryResult,
    estimatedRows: number | null = null,
    loopId: string = 'default'
): NodeResult => {
  let lastNodeId: string | null = parentNodeId;
  let accumulatedEstimatedRows: number | null = estimatedRows;

  for (const key in obj) {
    if (key === 'materialized_from_subquery') {
      const subQueryResult = handleSubQuery(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows
      );
      if (subQueryResult.nodeId) {
        lastNodeId = subQueryResult.nodeId;
      }
      if (subQueryResult.estimatedRows !== null && subQueryResult.estimatedRows !== undefined) {
        accumulatedEstimatedRows = subQueryResult.estimatedRows;
      }
      continue;
    }

    if (key === 'attached_subqueries') {
      const subQueriesResult = handleMultipleSubQueries(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows
      );

      if (subQueriesResult.nodeId) {
        lastNodeId = subQueriesResult.nodeId;
      }
      if (subQueriesResult.estimatedRows !== null && subQueriesResult.estimatedRows !== undefined) {
        accumulatedEstimatedRows = subQueriesResult.estimatedRows;
      }
      continue;
    }

    if (keyToHandlerMap[key as SQLOperationType]) {
      const result = recursiveNodeBuilder(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          discoveryResult,
          accumulatedEstimatedRows,
          loopId
      );
      if (result.nodeId) {
        lastNodeId = result.nodeId;
      }
      if (result.estimatedRows !== null && result.estimatedRows !== undefined) {
        accumulatedEstimatedRows = result.estimatedRows;
      }

      const currentResult = keyToHandlerMap[key as SQLOperationType](
          obj[key],
          context,
          nodes,
          edges,
          context.currentSubQueryGroupNodeId ? context.currentSubQueryGroupNodeId : lastNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows,
          loopId
      );

      if (currentResult.nodeId) {
        lastNodeId = currentResult.nodeId;
      }
      if (currentResult.estimatedRows !== null && currentResult.estimatedRows !== undefined) {
        accumulatedEstimatedRows = currentResult.estimatedRows;
      }
      if (
          currentResult.nodeId &&
          parentNodeId &&
          key !== SQLOperationType.NESTED_LOOP &&
          !edges.some(edge => edge.source === currentResult.nodeId && edge.target === parentNodeId)
      ) {
        edges.push(createEdge(currentResult.nodeId, parentNodeId, context));
      }
    }
  }

  return { nodeId: lastNodeId, estimatedRows: accumulatedEstimatedRows || 0 };
};



const handleSubQuery = (
    subQueryObj: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const previousParentId = context.currentParentId;

  const subqueryGroupNodeId = `subquery_${context.nodeIdCounter++}`;
  context.currentParentId = subqueryGroupNodeId;

  const subqueryNodes: SQLExplainNode[] = [];
  const subqueryEdges: SQLExplainEdge[] = [];

  const subqueryResult = recursiveNodeBuilder(
      subQueryObj.query_block,
      context,
      subqueryNodes,
      subqueryEdges,
      null,
      createdTableNodesMap,
      { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
      estimatedRows
  );

  const tableNodeCount = subqueryNodes.filter(node => node.data.type === 'table').length;
  let totalNodeCount = subqueryNodes.length;

  if (subQueryObj.query_block.sorting_operation) {
    totalNodeCount += 1;
  }

  if (subQueryObj.query_block.grouping_operation) {
    totalNodeCount += 1;
  }

  const nodeWidth = 250;
  const nodeHeight = 100;
  const horizontalSpacing = 50;
  const verticalSpacing = 70;
  const minGroupWidth = 460;
  const minGroupHeight = 420;

  const totalNodesWidth = tableNodeCount * nodeWidth + (tableNodeCount - 1) * horizontalSpacing;
  const calculatedGroupWidth = totalNodesWidth + 2 * horizontalSpacing;
  const groupWidth = Math.max(calculatedGroupWidth, minGroupWidth);

  const calculatedGroupHeight = totalNodeCount * (nodeHeight + verticalSpacing) + verticalSpacing;
  const groupHeight = Math.max(calculatedGroupHeight, minGroupHeight);

  const groupNodeX = context.currentXPosition - 360;
  const groupNodeY = context.currentYPosition + 460;

  const subqueryGroupNode: SQLExplainNode = {
    id: subqueryGroupNodeId,
    type: 'sql-sub-query-node',
    data: {
      label: `${subqueryGroupNodeId}`,
      type: 'group',
      width: groupWidth,
      height: groupHeight
    },
    position: {
      x: groupNodeX,
      y: groupNodeY
    },
    parentId: parentNodeId || undefined,
    extent: 'parent'
  };
  nodes.push(subqueryGroupNode);

  const xOffset = (groupWidth - totalNodesWidth) / 2;
  let currentXPosition = xOffset;
  let currentYPosition = groupHeight - nodeHeight - 50;
  const centerTableNodesX = xOffset + totalNodesWidth / 2;

  subqueryNodes.forEach(node => {
    node.parentId = subqueryGroupNodeId;
    node.extent = 'parent';

    if (node.data.type === 'table') {
      node.position.x = currentXPosition;
      node.position.y = currentYPosition;

      currentXPosition += nodeWidth + horizontalSpacing;
    } else {
      node.position.x = centerTableNodesX - nodeWidth / 2;
      currentYPosition -= nodeHeight + verticalSpacing;
      node.position.y = currentYPosition;
    }
  });
  nodes.push(...subqueryNodes);
  edges.push(...subqueryEdges);

  const queryResultNodeId = `query_result_${subqueryGroupNodeId}`;
  const queryResultNode: SQLExplainNode = {
    id: queryResultNodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Query Result',
      type: 'result',
      queryCost: subQueryObj.cost_info?.query_cost || null,
      estimatedRows: subqueryResult.estimatedRows || 'N/A'
    },
    position: {
      x: centerTableNodesX - nodeWidth / 2,
      y: currentYPosition - nodeHeight - verticalSpacing
    },
    parentId: subqueryGroupNodeId,
    extent: 'parent'
  };
  nodes.push(queryResultNode);

  if (subqueryResult.nodeId) {
    edges.push(createEdge(subqueryResult.nodeId, queryResultNodeId, context));
  }

  context.currentParentId = previousParentId;
  context.currentYPosition = groupNodeY - groupHeight - verticalSpacing;

  return { nodeId: queryResultNodeId, estimatedRows: subqueryResult.estimatedRows };
};

const handleUnion = (
    unionData: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const reactFlowWidth = window.innerWidth;
  const groupPadding = 50;
  const numberOfGroups = unionData.query_specifications.length;
  let maxGroupWidth = 0;
  let totalHeight = 0;
  const initialY = context.currentYPosition;

  const unionNode = createUnionNode(context);
  nodes.push(unionNode);
  const totalGroupsWidth = numberOfGroups * (400 + groupPadding) - groupPadding;
  const centeredX = (reactFlowWidth - totalGroupsWidth) / 2;

  unionData.query_specifications.forEach((queryBlock: any, index: number) => {
    const groupNodeId = `group_${index}`;
    const nestedLoopLength = queryBlock.query_block?.nested_loop?.length * 2 || 1;
    const groupSpacing = index > 1
        ? queryBlock.query_block?.nested_loop?.[index - 1]?.length * 400 || 460
        : queryBlock.query_block?.nested_loop?.length * 440 || 460;

    const groupWidth = Math.max(nestedLoopLength * 220, 400);
    const groupHeight = Math.max(nestedLoopLength * 240, 500);
    maxGroupWidth = Math.max(maxGroupWidth, groupWidth);
    totalHeight += groupHeight + 100;


    const groupInitialX = centeredX + index * (groupSpacing + groupPadding);
    const groupInitialY = initialY;

    const groupNode = createGroupNode(groupNodeId, context, groupWidth, groupHeight);
    groupNode.position.x = groupInitialX;
    groupNode.position.y = groupInitialY;
    nodes.push(groupNode);

    context.currentXPosition = 0;
    context.currentYPosition = 0;

    const subNodes: SQLExplainNode[] = [];
    const queryBlockResult = recursiveNodeBuilder(
        queryBlock.query_block,
        context,
        subNodes,
        edges,
        groupNode.id,
        createdTableNodesMap,
        {
          hasNestedLoop: true,
          tableNames: new Set(),
          unionCount: 0,
          subqueryCount: 0,
        },
        estimatedRows,
        groupNodeId
    );

    subNodes.forEach((node, idx) => {
      if (!node.parentId) {
        node.parentId = groupNodeId;
      }
      node.position.x += subNodes.length === 1 ? groupWidth / 1.25 : groupWidth * 1.125;
      node.position.y += groupHeight - 150 * (idx + 1);
    });

    nodes.push(...subNodes);

    if (!nodes.some((node) => node.id === `query_result_${groupNodeId}`)) {
      const resultNode = createResultNode(queryBlock.query_block, context, queryBlockResult.estimatedRows, groupNode.id);
      resultNode.id = `query_result_${groupNodeId}`;
      resultNode.position.x = groupWidth / 2 - 125;
      resultNode.position.y = groupNode.position.y + 50;
      nodes.push(resultNode);

      if (queryBlockResult.nodeId) {
        edges.push(createEdge(queryBlockResult.nodeId, resultNode.id, context));
      }
      edges.push(createEdge(resultNode.id, unionNode.id, context));
    }
  });

  const unionNodeX = centeredX + totalGroupsWidth / 2 - 125;
  const unionNodeY = initialY - totalHeight / 5;
  unionNode.position.x = unionNodeX;
  unionNode.position.y = unionNodeY;

  context.currentXPosition = unionNodeX;
  context.currentYPosition = unionNodeY - 200;

  const nextNodeInFlow = nodes.find((node) => node.data.type === 'result' && node.id.startsWith('node_') && node.id !== unionNode.id);

  if (nextNodeInFlow) {
    nextNodeInFlow.position.x = context.currentXPosition;
    nextNodeInFlow.position.y = context.currentYPosition;
    edges.push(createEdge(unionNode.id, nextNodeInFlow.id, context));
  }

  if (parentNodeId && nextNodeInFlow) {
    edges.push(createEdge(nextNodeInFlow.id, parentNodeId, context));
  }

  return { nodeId: unionNode.id, estimatedRows };
};


const handleQueryBlock = (
    queryBlock: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const resultNode = createResultNode(queryBlock, context, estimatedRows);
  nodes.push(resultNode);

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === resultNode.id)) {
    edges.push(createEdge(parentNodeId, resultNode.id, context));
  }

  return { nodeId: resultNode.id, estimatedRows };
};
const handleGroupingOperation = (
    groupingOperation: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const groupByNode = createGroupByNode(context, parentNodeId || undefined);
  nodes.push(groupByNode);
  context.currentYPosition += context.verticalSpacing;

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === groupByNode.id)) {
    edges.push(createEdge(parentNodeId, groupByNode.id, context));
  }

  return { nodeId: groupByNode.id, estimatedRows };
};


const handleTable = (
    table: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    loopId: string
): NodeResult => {
  const currentSet = createdTableNodesMap.get(loopId) || new Set<string>();

  if (currentSet.has(table.table_name)) {
    return { nodeId: parentNodeId, estimatedRows };
  }

  currentSet.add(table.table_name);
  createdTableNodesMap.set(loopId, currentSet);

  const tableNode = createTableNode(table, context, context.currentSubQueryGroupNodeId ? context.currentSubQueryGroupNodeId : undefined);
  nodes.push(tableNode);

  const tableRows = table.rows_produced_per_join || estimatedRows || 0;

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === tableNode.id)) {
    edges.push(createEdge(parentNodeId, tableNode.id, context));
  }

  return { nodeId: tableNode.id, estimatedRows: tableRows };
};

const handleOrderingOperation = (
    orderingOperation: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const sortingNode = createSortingNode(context, parentNodeId || undefined);
  nodes.push(sortingNode);

  context.currentYPosition += context.verticalSpacing;

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === sortingNode.id)) {
    edges.push(createEdge(parentNodeId, sortingNode.id, context));
  }

  return { nodeId: sortingNode.id, estimatedRows };
};

const handleNestedLoop = (
    nestedLoops: any[],
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    loopId: string
): NodeResult => {
  let lastNodeId: string | null = null;
  let finalEstimatedRows: number | null = estimatedRows;

  const nestedLoopId = `${loopId}-nestedloop-${context.nodeIdCounter}`;

  if (!createdTableNodesMap.has(nestedLoopId)) {
    createdTableNodesMap.set(nestedLoopId, new Set<string>());
  }

  const currentSet = createdTableNodesMap.get(nestedLoopId)!;

  nestedLoops.forEach((loop: any, index: number) => {
    context.currentXPosition -= context.horizontalSpacing;

    if (loop.table) {
      if (loop.table.materialized_from_subquery) {
        const subQueryResult = handleSubQuery(
            loop.table.materialized_from_subquery,
            context,
            nodes,
            edges,
            parentNodeId,
            createdTableNodesMap,
            estimatedRows
        );

        const outerTableNodeData = {
          ...loop.table,
          estimatedRows: subQueryResult.estimatedRows || 'N/A',
        };
        const outerTableNode = createTableNode(outerTableNodeData, context, parentNodeId);
        nodes.push(outerTableNode);

        if (subQueryResult.nodeId) {
          edges.push(createEdge(subQueryResult.nodeId, outerTableNode.id, context));
        }

        if (index !== 0 && lastNodeId) {
          const maxFilteredRows = Math.max(Number(loop.table.rows_produced_per_join) || 0, context.latestRowsAfterFilter || 0);
          finalEstimatedRows = maxFilteredRows;

          const joinNode = createJoinNode(maxFilteredRows, loop.table.filtered || 'No Filter', context, parentNodeId);
          nodes.push(joinNode);

          // Connect the previous node and the current outer table node to the join node
          edges.push(createEdge(lastNodeId, joinNode.id, context));
          edges.push(createEdge(outerTableNode.id, joinNode.id, context));

          lastNodeId = joinNode.id;
        } else {
          lastNodeId = outerTableNode.id;
        }

      } else {
        const tableNode = createTableNode(loop.table, context, parentNodeId);
        nodes.push(tableNode);
        currentSet.add(loop.table.table_name);

        if (index !== 0 && lastNodeId) {
          const maxFilteredRows = Math.max(Number(loop.table.rows_produced_per_join) || 0, context.latestRowsAfterFilter || 0);
          finalEstimatedRows = maxFilteredRows;

          const joinNode = createJoinNode(maxFilteredRows, loop.table.filtered || 'No Filter', context, parentNodeId);
          nodes.push(joinNode);

          edges.push(createEdge(lastNodeId, joinNode.id, context));
          edges.push(createEdge(tableNode.id, joinNode.id, context));

          lastNodeId = joinNode.id;
        } else {
          lastNodeId = tableNode.id;
        }
      }
    }
  });

  if (lastNodeId && parentNodeId) {
    edges.push(createEdge(lastNodeId, parentNodeId, context));
  }

  return { nodeId: lastNodeId, estimatedRows: finalEstimatedRows };
};

const handleDuplicatesRemoval = (
    duplicatesRemoval: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const distinctNode = createDistinctNode(context, parentNodeId || undefined);
  nodes.push(distinctNode);
  context.currentYPosition += context.verticalSpacing;

  if (
      parentNodeId &&
      !edges.some(
          (edge) => edge.source === parentNodeId && edge.target === distinctNode.id
      )
  ) {
    edges.push(createEdge(parentNodeId, distinctNode.id, context));
  }

  return { nodeId: distinctNode.id, estimatedRows };
};

const handleMultipleSubQueries = (
    subQueries: any[],
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  let lastSubQueryNodeId: string | null = null;
  let accumulatedEstimatedRows = estimatedRows;

  subQueries.forEach((subQueryObj) => {
    const subQueryResult = handleSubQuery(
        subQueryObj,
        context,
        nodes,
        edges,
        parentNodeId,
        createdTableNodesMap,
        accumulatedEstimatedRows
    );

    if (subQueryResult.nodeId) {
      lastSubQueryNodeId = subQueryResult.nodeId;
    }
    if (subQueryResult.estimatedRows !== null && subQueryResult.estimatedRows !== undefined) {
      accumulatedEstimatedRows = subQueryResult.estimatedRows;
    }

    if (subQueryResult.nodeId && parentNodeId) {
      edges.push(createEdge(subQueryResult.nodeId, parentNodeId, context));
    }
  });

  return { nodeId: lastSubQueryNodeId, estimatedRows: accumulatedEstimatedRows };
};


const keyToHandlerMap: Record<
    any,
    any
> = {
  [SQLOperationType.QUERY_RESULT]: handleQueryBlock,
  [SQLOperationType.TABLE]: handleTable,
  [SQLOperationType.GROUP_BY]: handleGroupingOperation,
  [SQLOperationType.SORT]: handleOrderingOperation,
  [SQLOperationType.NESTED_LOOP]: handleNestedLoop,
  [SQLOperationType.UNION]: handleUnion,
  [SQLOperationType.SUBQUERY]: handleSubQuery,
  [SQLOperationType.DISTINCT]: handleDuplicatesRemoval,
  [SQLOperationType.MULTIPLE_SUBQUERIES]: handleMultipleSubQueries
};

