import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

type InnodbBufferPoolSizeInsightProps = {
    val_innodb_flush_log_at_trx_commit: number;
}
const InnodbFlushLogAtTrxCommitInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        const { val_innodb_flush_log_at_trx_commit } = params as InnodbBufferPoolSizeInsightProps;
        return (
            <div>The <code style={{backgroundColor: '#E0E0E0'}}>innodb_flush_log_at_trx_commit</code> parameter controls
                how often the InnoDB storage engine flushes transaction logs to disk. It has three settings:
                <ul>
                    <li>1: (default): Logs are flushed to disk at each transaction commit, ensuring full ACID compliance
                        but with potential performance overhead.
                    </li>

                    <li>2: Logs are written to disk at each commit, but flushed to disk once per second, offering a
                        balance between performance and durability.
                    </li>

                    <li>0: Logs are written and flushed once per second, offering better performance but risking data
                        loss in a crash.
                    </li>
                </ul>
                <br/>
                The current configuration is: {val_innodb_flush_log_at_trx_commit}
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>Data Loss: If <code style={{backgroundColor: '#E0E0E0'}}>innodb_flush_log_at_trx_commit</code> is not set
                to 1 in a production database, it increases the risk of data loss in the event of a crash.</div>
        )
    }

    const getRemediation = () => {
        return (
            <div>Enable the setting by adding or updating the following line in your MySQL configuration file (<code
                style={{backgroundColor: '#E0E0E0'}}>my.cnf</code> or <code style={{backgroundColor: '#E0E0E0'}}>my.ini</code>):
                <br/>
                <div style={{backgroundColor: '#f4f4f4', padding: 5, borderRadius: 5, border: '1px solid #ddd'}}>
                    <code>innodb_flush_log_at_trx_commit=1</code></div>
                <br/>
                <div>After making this change, restart your MySQL service to apply the configuration. You can do this
                    either through your cloud provider's console or by running the following command:
                </div>
                <div style={{backgroundColor: '#f4f4f4', padding: 5, borderRadius: 5, border: '1px solid #ddd'}}><code>sudo
                    systemctl restart mysql
                </code></div>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'innodb_flush_log_at_trx_commit'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default InnodbFlushLogAtTrxCommitInsight;