import styled from 'styled-components';

export const SidebarContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 8px;
    padding-left: 0;
    margin-left: 0;
`;

export const NodeDetailsTable = styled.table`
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    margin-left: 2px;
    padding-left: 50px;
`;

export const TableHeader = styled.th`
    text-align: left;
    margin: 0;  
    padding-left: 2px;  
    font-weight: bold;
 
`;
