import { KyInstance } from 'ky/distribution/types/ky';
import * as Sentry from '@sentry/react';
import { SeveritiesI } from '../../types/Severity.type';

export const getTableAnalysisAPI = async (
  restClient: KyInstance,
  tableID: string,
  dbId: string,
  projectApiKey: string
): Promise<any[]> => {
  try {
    const resTableList: any = await restClient
      .get(`db-details/ssa/${dbId}/${tableID}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();
    if (resTableList) {
      return resTableList;
    } else return [];
  } catch (error: any) {
    return [];
  }
};

export const getTableIndexesAPI = async (
  restClient: KyInstance,
  tableName: string,
  schemaName: string,
  dbId: string,
  projectApiKey: string
): Promise<any[]> => {
  try {
    const data = await restClient.get(`db-details/ssa/indexes/${dbId}/${schemaName}/${tableName}`, {
      headers: {
        'x-api-key': projectApiKey,
      },
    }).json();

   return data as any[] || []
  } catch (error) {
    console.error('Error fetching table indexes:', error);
    return [];
  }
};

//need-that
export const getTableDBIdAndParamsAPI = async (
  restClient: KyInstance,
  tableName: string,
  schemaName: string,
  databaseId: string,
  projectApiKey: string
): Promise<any[]> => {
  try {
    const resTableList: any[] = await restClient
      .get(`db-details/ssa/table-details/${databaseId}/${schemaName}/${tableName}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();
    if (resTableList) {
      return resTableList;
    } else return [];
  } catch (error: any) {
    return [];
  }
};

export const getSSA_ERD_API = async (
  restClient: KyInstance,
  databaseId = '',
  projectApiKey = ''
): Promise<any> => {
  try {
   
    const erdData: any[] = await restClient
      .get(`db-details/ssa/erd/${databaseId}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();

    return erdData;
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getSSA_API-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return {};
  }
};

export const getTableInsightsAPI = async (restClient: KyInstance,
                                       hostId: string,
                                       dbId: string,
                                       schemaId: string,
                                       tableId: number,
                                       projectApiKey: string) => {
  try {
    const tableInsights: any[] = await restClient
        .get(`insights/table`, {
          headers: {
            'x-api-key': projectApiKey
          },
          searchParams: {hostId, dbId, schemaId, tableId}
        })
        .json();

    return tableInsights;
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getTableInsights-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return [];
  }
}
export const getSSA_API = async (
  restClient: KyInstance,
  databaseId = '',
  projectApiKey = ''
): Promise<{ listItem: any[]; totalSeverities?: SeveritiesI }> => {
  try {
    const listItem: any[] = [];
    const totalSeverities: SeveritiesI = { severity_critical: 0, severity_high: 0, severity_medium: 0, severity_low: 0 };

    const resSSAList: any[] = await restClient
      .get(`db-details/ssa/${databaseId}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();

    const insightsPerTable: any[] = await restClient
        .get(`insights/summery/table/`, {
          headers: {
            'x-api-key': projectApiKey
          },
          searchParams: { dbId: databaseId }
        })
        .json();

    if (resSSAList.length) {
      resSSAList.map((item) => {
        if (item.tables?.length) {
          item.tables.forEach((table: any) => {
            const tableInsights = insightsPerTable[table.table_id];
            if (tableInsights) {
              totalSeverities.severity_critical = totalSeverities.severity_critical + tableInsights?.severity_critical || 0;
              totalSeverities.severity_high = totalSeverities.severity_high + tableInsights?.severity_high || 0;
              totalSeverities.severity_medium = totalSeverities.severity_medium + tableInsights?.severity_medium || 0;
              totalSeverities.severity_low = totalSeverities.severity_low + tableInsights?.severity_low || 0;
            }
            listItem.push({ ...table, schema_name: item?.schema_name, updated_at: item?.updated_at });
          });
        }
      });
    }
    return { listItem, totalSeverities };
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getSSA_API-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return { listItem: [] };
  }
};
export const getChangeLogSSA_API = async (restClient: KyInstance, databaseId = '', projectApiKey = ''): Promise<any> => {
  try {
    const resSSALog: any = await restClient
      .get('db-details/schema/change-log', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: { dbId: databaseId }
      })
      .json();
    if (resSSALog) {
      return resSSALog;
    }
    return [];
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getChangeLogSSA_API-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return [];
  }
};

export const getSchemaSVG_API = async (restClient: KyInstance, databaseId = '', projectApiKey = ''): Promise<string> => {
  try {
    const resSSALog: any = await restClient
      .get('db-details/schema/svg', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: { dbId: databaseId }
      })
      .text();
    if (resSSALog) {
      return resSSALog.replace(/width="[^"]*"/, 'width="100%"').replace(/height="[^"]*"/, 'height="100%"');
    }
    return '';
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getSchemaSVG_API-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    return '';
  }
};
