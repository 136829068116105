import styled from "styled-components";
import { ReactComponent as Arrow } from '@icons/accordion-arrow.svg';

export const AccordionContainer = styled.div`
  width: 100%;
   display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
`;

export const AccordionHeader = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: bold;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AccordionContent = styled.div`
    max-height: ${({ isOpen }) => (isOpen ? '300px' : '0px')};
    transition: max-height 0.3s ease-in-out;
`;

export const Content = styled.div`
  padding: 10px;
`;

export const ArrowIcon = styled(Arrow)`
  stroke: ${({ theme }) => theme.colors.new.primary.main}};
  height: 24px;
  width: 24px;
    transition: transform 0.3s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export const Title = styled.div`
  flex-grow: 0; 
  margin-right: auto;
`;

export const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; 
`;
